import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getTownships = createAsyncThunk(
  'township/getTownships',
  async (query) => {
    try {
      const result = await call(
        'get',
        `townships?${new URLSearchParams(query).toString()}`,
      )
      
      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getTownship = createAsyncThunk(
  'township/getTownship',
  async (id) => {
    try {
      const response = await call('get', `townships/${id}`)
      const result = response.data

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const createTownship = createAsyncThunk(
  'township/createTownship',
  async (data) => {
    try {
      const response = await call('post', 'townships', data)
      const result = response.data

      NotificationManager.success('Township has been created successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const updateTownship = createAsyncThunk(
  'township/updateTownship',
  async (data) => {
    try {
      const response = await call(
        'post',
        `townships/${data.id}?_method=PUT`,
        data,
      )
      const result = response.data

      NotificationManager.success('Township has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const deleteTownship = createAsyncThunk(
  'township/deleteTownship',
  async (id) => {
    try {
      await call('delete', `townships/${id}`)

      NotificationManager.success('Township has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)
