import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { createOtherTOTownship } from "store/actions/otherTOTownship";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { years } from "variables/options";

const OtherTOTownshipCreate = ({ isOpen, toggle, id }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [selectedTOYear, setSelectedTOYear] = useState(null);
  const [selectedTOTownship, setSelectedTOTownship] = useState(null);

  const { success } = useSelector((state) => state.otherTOTownship);
  const townships = useSelector((state) => state.township.townships);
  const dispatch = useDispatch();

  const townshipOptions = townships?.map((township) => {
    return { value: township.id, label: township.name };
  });

  const onSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      createOtherTOTownship({
        patient_id: id,
        TO_year: selectedTOYear?.value,
        TO_date: values.TO_date,
        TO_RR_code: values.TO_RR_code,
        TO_DRTB_code: values.TO_DRTB_code,
        TO_township_id: selectedTOTownship?.value,
      })
    );
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedTOYear(null);
    setSelectedTOTownship(null);
    reset();
  }, [reset, setSelectedTOYear, setSelectedTOTownship]);

  useEffect(() => {
    if (success) {
      formReset();
    }
  }, [success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Other TO Township"
      onClick={() => {
        toggle(false);
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="TO_year"
            label="TO Year"
            rules={{ required: "TO Year is required!" }}
            control={control}
            options={years}
            value={selectedTOYear}
            setData={setSelectedTOYear}
            placeholder="TO Year"
          />

          <CustomDateInput
            id="TO_date"
            label="TO_date"
            register={{
              ...register("TO_date", {
                required: "TO_date is required!",
              }),
            }}
            placeholder="Select TO_date"
            errors={errors}
            isRequired={true}
            max={currentDate()}
          />

          <CustomSelect
            id="TO_township_id"
            label="TO Township"
            rules={{ required: "TO Township is required!" }}
            control={control}
            options={townshipOptions}
            value={selectedTOTownship}
            setData={setSelectedTOTownship}
            placeholder="TO Township"
          />

          <CustomInput
            id="TO_RR_code"
            label="TO RR Code"
            register={{
              ...register("TO_RR_code", {
                required: false,
              }),
            }}
            placeholder="Enter TO RR Code"
            errors={errors}
            isRequired={false}
            onChange={() => {
              setValue("TO_DRTB_code", "");
            }}
          />

          <CustomInput
            id="TO_DRTB_code"
            label="TO DRTB Code"
            register={{
              ...register("TO_DRTB_code", {
                required: false,
              }),
            }}
            placeholder="Enter TO DRTB Code"
            errors={errors}
            isRequired={false}
            onChange={() => {
              setValue("TO_RR_code", "");
            }}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default OtherTOTownshipCreate;
