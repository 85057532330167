import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import {
  getSupportPackages,
  recoverSupportPackage,
  deleteSupportPackage,
} from "store/actions/supportPackage";

const TrashSupportPackageList = () => {
  const [alert, setAlert] = useState(null);
  const { supportPackage } = useSelector((state) => state);
  const { supportPackages, loading, total } = supportPackage;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSupportPackages());
  }, [dispatch]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deleteSupportPackage(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const handleRecover = async (id) => {
    await dispatch(recoverSupportPackage(id));
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Support Packages</h3>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Township</th>
                <th scope="col">Patient</th>
                <th scope="col">Support Received Date</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={supportPackages?.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.township?.name}</td>
                <td>{row.patient?.name}</td>
                <td>{row.date}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => handleRecover(row.id)}
                  >
                    Recover
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default TrashSupportPackageList;
