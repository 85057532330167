import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatient } from "store/actions/patient";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomSelect from "components/Inputs/CustomSelect";
import { useForm } from "react-hook-form";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { updatePatientOutcome } from "store/actions/patient";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { yesOrNo } from "variables/options";
import { reportedPeriods } from "variables/options";

const PatientReportEdit = () => {
  const { control, handleSubmit, reset } = useForm();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [isReported, setIsReported] = useState("");
  const [reportedPeriod, setReportedPeriod] = useState("");

  const dispatch = useDispatch();
  const { loading: patientLoading, patient } = useSelector(
    (state) => state.patient
  );
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    const { outcome, outcome_date, remark } = patient;
    reset({
      outcome: outcome
        ? {
            value: outcome,
            label: outcome,
          }
        : null,
      outcome_date: outcome_date,
      remark: remark,
    });
  }, [reset, patient]);

  const onSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      updatePatientOutcome({
        id: patient.id,
        is_reported: isReported?.value,
        report_period: reportedPeriod?.value,
      })
    );

    setLoading(false);
  };

  useEffect(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  if (patientLoading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Updating Report Outcome</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="reported"
                    label="Reported"
                    rules={{ required: false }}
                    control={control}
                    options={yesOrNo}
                    value={isReported}
                    setData={setIsReported}
                    placeholder="Reported"
                    isRequired={false}
                  />
                </Col>
                {isReported?.value === "Yes" && (
                  <Col md={4}>
                    <CustomSelect
                      id="reporting_period"
                      label="Reporting Period"
                      rules={{ required: true }}
                      control={control}
                      options={reportedPeriods}
                      value={reportedPeriod}
                      setData={setReportedPeriod}
                      placeholder="Reporting Period"
                      isRequired={false}
                    />
                  </Col>
                )}
              </Row>
            </CardBody>
            {patientMiddleware(user?.role, UPDATE) && (
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default PatientReportEdit;
