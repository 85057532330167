import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import TownshipCreate from "./TownshipCreate";
import CustomTable from "components/Table/Table";
import TownshipUpdate from "./TownshipUpdate";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  getTownships,
  getTownship,
  deleteTownship,
} from "store/actions/township";
import { getRegions } from "store/actions/region";
import { getDistricts } from "store/actions/district";
import { getProjects } from "store/actions/project";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { basicDataMiddleware, CREATE, UPDATE, DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";

const TownshipList = () => {
  const router = useHistory();

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const [alert, setAlert] = useState(null);

  const { township, auth } = useSelector((state) => state);
  const { loading, townships } = township;
  const { user } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getTownships(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDistricts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          dispatch(deleteTownship(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Townships</h3>
              </Col>

              {basicDataMiddleware(user.role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Region</th>
                <th scope="col">District</th>
                <th scope="col">Project</th>
                <th scope="col">Township</th>
                <th scope="col">Acronym</th>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={townships?.map((township, index) => (
              <tr key={township.id}>
                <td>{index + 1}</td>
                <td>{township.region.name}</td>
                <td>{township.district.name}</td>
                <td>{township.project.name}</td>
                <td>{township.name}</td>
                <td>{township.abbreviation}</td>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await dispatch(getTownship(township.id));
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(township.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
          {township.total > paginationLimit && (
            <CustomPagination pageCount={township.total / paginationLimit} />
          )}
        </Card>
      </Container>

      <TownshipUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <TownshipCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

export default TownshipList;
