import { createSlice } from "@reduxjs/toolkit";
import {
  createPackage,
  getPackages,
  getPackage,
  updatePackage,
  deletePackage,
} from "store/actions/package";

const initialState = {
  loading: false,
  success: false,
  packages: [],
  package: {},
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  extraReducers: (builder) => {
    // Get Packages
    builder.addCase(getPackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.packages = action.payload;
    });
    builder.addCase(getPackages.rejected, (state) => {
      state.loading = false;
    });

    // Create Packages
    builder.addCase(createPackage.pending, (state) => {
      state.success = false;
    });
    builder.addCase(createPackage.fulfilled, (state, action) => {
      state.success = true;
      state.packages = [action.payload, ...state.packages];
    });
    builder.addCase(createPackage.rejected, (state) => {
      state.success = false;
    });

    // Get Package
    builder.addCase(getPackage.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.package = action.payload;
    });
    builder.addCase(getPackage.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    // Update Package
    builder.addCase(updatePackage.pending, (state) => {
      state.success = false;
    });
    builder.addCase(updatePackage.fulfilled, (state, action) => {
      let index = state.packages.findIndex((p) => p.id === action.payload.id);
      state.packages[index] = action.payload;
      state.success = true;
      state.package = action.payload;
    });
    builder.addCase(updatePackage.rejected, (state) => {
      state.success = false;
    });

    // Delete Package
    builder.addCase(deletePackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePackage.fulfilled, (state, action) => {
      state.loading = false;
      state.packages = state.packages.filter((p) => p.id !== action.payload);
    });
    builder.addCase(deletePackage.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default packageSlice.reducer;
