import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getOtherTOTownships = createAsyncThunk(
    'otherTOTownship/getOtherTOTownships',
    async (patientId) => {
      try {
        const response = await call(
          "get",
          `patients/${patientId}/other_TO_townships`
        );
        const result = response.data
  
        return result
      } catch (error) {
        const { status, data } = error.response
  
        if (status === 401) {
          setAccessToken(null)
          NotificationManager.error(data.data.message)
        } else {
          NotificationManager.error(serverErrorMessage)
        }
        return error.message
      }
    },
  )

  export const getOtherTOTownship = createAsyncThunk(
    "otherTOTownship/getOtherTOTownship",
    async (id) => {
      try {
        const response = await call("get", `other_TO_townships/${id}`);
        const result = response.data;

        return result;
      } catch (error) {
        const { status, data } = error.response;

        if (status === 401) {
          setAccessToken(null);
          NotificationManager.error(data.data.message);
        } else {
          NotificationManager.error(serverErrorMessage);
        }
        return error.message;
      }
    }
  );

  export const createOtherTOTownship = createAsyncThunk(
    'otherTOTownship/createOtherTOTownship',
    async (data) => {
      try {
        const response = await call('post', 'other_TO_townships', data)
        const result = response.data
  
        NotificationManager.success('Other TO Township has been created successfully!')
        return result
      } catch (error) {
        const { status, data } = error.response
        if (status === 400) {
          const obj = data.data
          NotificationManager.error(obj[Object.keys(obj)[0]])
        } else if (status === 401) {
          setAccessToken(null)
          NotificationManager.error(data.data.message)
        } else {
          NotificationManager.error(serverErrorMessage)
        }
        return error.message
      }
    },
  )


export const updateOtherTOTownship = createAsyncThunk(
  "patient/updateOtherTOTownship",
  async (data, thunkAPI) => {
    try {
      const response = await call(
        "post",
        `other_TO_townships/${data.id}?_method=PUT`,
        data
      );
      const result = response.data;

      NotificationManager.success(
        "Other TO Township has been updated successfully!"
      );
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

  export const deleteOtherTOTownship = createAsyncThunk(
    'otherTOTownship/deleteOtherTOTownship',
    async (id) => {
      try {
        await call('delete', `other_TO_townships/${id}`)
  
        NotificationManager.success('Other TO Township has been deleted successfully!')
        return id
      } catch (error) {
        const { status, data } = error.response
  
        if (status === 401) {
          setAccessToken(null)
          NotificationManager.error(data.data.message)
        } else {
          NotificationManager.error(serverErrorMessage)
        }
        return error.message
      }
    },
  )