export const calculateBMI = (value) => {
  if (value <= 0) {
    return ''
  } else if (value > 0 && value <= 18.5) {
    return '(Underweight)'
  } else if (value >= 18.5 && value <= 24.5) {
    return '(Healthy)'
  } else if (value > 24.5 && value <= 29.5) {
    return '(Overweight)'
  } else if (value > 29.5 && value <= 39.5) {
    return '(Obese)'
  } else {
    return '(Extermely Obese)'
  }
}
