const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  localPackage: {},
}

const localPackageSlice = createSlice({
  name: 'localPackage',
  initialState,
  reducers: {
    setLocalPackage: (state, action) => {
      state.localPackage = { ...state.localPackage, ...action.payload }
    },
    setOverrideLocalPackage: (state, action) => {
      state.localPackage = action.payload
    },
  },
})

export default localPackageSlice.reducer
export const {
  setLocalPackage,
  setOverrideLocalPackage,
} = localPackageSlice.actions
