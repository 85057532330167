import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getRegions = createAsyncThunk('region/getRegions', async () => {
  try {
    const response = await call('get', 'regions')
    const result = response.data

    return result
  } catch (error) {
    const { status, data } = error.response

    if (status === 401) {
      setAccessToken(null)
      NotificationManager.error(data.data.message)
    } else {
      NotificationManager.error(serverErrorMessage)
    }
    return error.message
  }
})

export const getRegion = createAsyncThunk('region/getRegion', async (id) => {
  try {
    const response = await call('get', `regions/${id}`)
    const result = response.data

    return result
  } catch (error) {
    const { status, data } = error.response

    if (status === 401) {
      setAccessToken(null)
      NotificationManager.error(data.data.message)
    } else {
      NotificationManager.error(serverErrorMessage)
    }
    return error.message
  }
})

export const createRegion = createAsyncThunk(
  'region/createRegion',
  async (data) => {
    try {
      const response = await call('post', 'regions', data)
      const result = response.data

      NotificationManager.success('Region has been created successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const updateRegion = createAsyncThunk(
  'region/updateRegion',
  async (data) => {
    try {
      const response = await call(
        'post',
        `regions/${data.id}?_method=PUT`,
        data,
      )
      const result = response.data

      NotificationManager.success('Region has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const deleteRegion = createAsyncThunk(
  'region/deleteRegion',
  async (id) => {
    try {
      await call('delete', `regions/${id}`)

      NotificationManager.success('Region has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)
