import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "services/api";
import { serverErrorMessage } from "utils/messages";
import { NotificationManager } from "react-notifications";
import { setAccessToken } from "services/api";

export const getPatients = createAsyncThunk(
  "patient/getPatients",
  async (query) => {
    try {
      const result = await call(
        "get",
        `patients?${new URLSearchParams(query).toString()}`
      );
      return result;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const getPatient = createAsyncThunk("patient/getPatient", async (id) => {
  try {
    const response = await call("get", `patients/${id}`);
    const result = response.data;

    return result;
  } catch (error) {
    const { status, data } = error.response;

    if (status === 401) {
      setAccessToken(null);
      NotificationManager.error(data.data.message);
    } else {
      NotificationManager.error(serverErrorMessage);
    }
    return error.message;
  }
});

export const createPatient = createAsyncThunk(
  "patient/createPatient",
  async (data, thunkAPI) => {
    try {
      const response = await call("post", "patients", data);
      const result = response.data;

      NotificationManager.success("Patient has been created successfully!");
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePatient = createAsyncThunk(
  "patient/updatePatient",
  async (data, thunkAPI) => {
    try {
      const response = await call(
        "post",
        `patients/${data.id}?_method=PUT`,
        data
      );
      const result = response.data;

      NotificationManager.success("Patient has been updated successfully!");
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePatientOutcome = createAsyncThunk(
  "patient/updatePatientOutcome",
  async (data) => {
    try {
      const response = await call(
        "post",
        `patients-outcome/${data.id}?_method=PUT`,
        data
      );
      const result = response.data;

      NotificationManager.success(
        "Patient Outcome has been updated successfully!"
      );
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const recoverPatient = createAsyncThunk(
  "patient/recoverPatient",
  async (id) => {
    try {
      await call("get", `patients-recover/${id}`);

      NotificationManager.success("Patient has been recovered successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const temDeletePatient = createAsyncThunk(
  "patient/temDeletePatient",
  async (id) => {
    try {
      await call("get", `patients-delete/${id}`);

      NotificationManager.success("Patient has been deleted successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const deletePatient = createAsyncThunk(
  "patient/deletePatient",
  async (id) => {
    try {
      await call("delete", `patients/${id}`);

      NotificationManager.success("Patient has been deleted successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const getPfaPatients = createAsyncThunk(
  "patient/getPfaPatients",
  async (query) => {
    try {
      const result = await call(
        "get",
        `pfa-patients?${new URLSearchParams(query).toString()}`
      );
      return result;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const getPfaPatient = createAsyncThunk(
  "patient/getPfaPatient",
  async (id) => {
    try {
      const response = await call("get", `pfa-patients/${id}`);
      const result = response.data;

      return result;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const createPfaPatient = createAsyncThunk(
  "patient/createPfaPatient",
  async (data, thunkAPI) => {
    try {
      const response = await call("post", "pfa-patients", data);
      const result = response.data;

      NotificationManager.success("PfaPatient has been created successfully!");
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePfaPatient = createAsyncThunk(
  "patient/updatePfaPatient",
  async (data) => {
    try {
      const response = await call(
        "post",
        `pfa-patients/${data.id}?_method=PUT`,
        data
      );
      const result = response.data;

      NotificationManager.success("PfaPatient has been updated successfully!");
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const deletePfaPatient = createAsyncThunk(
  "patient/deletePfaPatient",
  async (id) => {
    try {
      await call("delete", `pfa-patients/${id}`);

      NotificationManager.success("Pfa Patient has been deleted successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const recoverPfaPatient = createAsyncThunk(
  "patient/recoverPfaPatient",
  async (id) => {
    try {
      await call("get", `pfa-patients-recover/${id}`);

      NotificationManager.success("Pfa Patient has been recovered successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);

export const temDeletePfaPatient = createAsyncThunk(
  "patient/temDeletePfaPatient",
  async (id) => {
    try {
      await call("get", `pfa-patients-delete/${id}`);

      NotificationManager.success("Pfa Patient has been deleted successfully!");
      return id;
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  }
);