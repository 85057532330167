import { createSlice } from '@reduxjs/toolkit'
import {
  createProject,
  getProjects,
  getProject,
  updateProject,
  deleteProject,
} from 'store/actions/project'

const initialState = {
  loading: false,
  success: false,
  projects: [],
  project: {},
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  extraReducers: (builder) => {
    // Get projects
    builder.addCase(getProjects.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.loading = false
      state.projects = action.payload
    })
    builder.addCase(getProjects.rejected, (state) => {
      state.loading = false
    })

    // Create projects
    builder.addCase(createProject.pending, (state) => {
      state.success = false
    })
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.success = true
      state.projects = [action.payload, ...state.projects]
    })
    builder.addCase(createProject.rejected, (state) => {
      state.success = false
    })

    // Get project
    builder.addCase(getProject.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.loading = false
      state.project = action.payload
    })
    builder.addCase(getProject.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update project
    builder.addCase(updateProject.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateProject.fulfilled, (state, action) => {
      let index = state.projects.findIndex(
        (project) => project.id === action.payload.id,
      )
      state.projects[index] = action.payload
      state.success = true
      state.project = action.payload
    })
    builder.addCase(updateProject.rejected, (state) => {
      state.success = false
    })

    // Delete project
    builder.addCase(deleteProject.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.loading = false
      state.projects = state.projects.filter(
        (project) => project.id !== action.payload,
      )
    })
    builder.addCase(deleteProject.rejected, (state) => {
      state.loading = false
    })
  },
})

export default projectSlice.reducer
