import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomModal from "components/Modal/CustomModal";
import { roles } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { updateAccount } from "store/actions/account";
import { getTownships } from "store/actions/township";

const AccountUpdate = ({ isOpen, toggle }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const { township, project } = useSelector((state) => state);
  const { account, success } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const [role, setRole] = useState(null);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const projects = project.projects?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const { name, role, townships, project } = account;

    setValue("name", name);
    setValue("role", { value: role, label: role });
    setRole({ value: role, label: role });
    setValue(
      "township",
      townships?.map((ele) => ({ value: ele.id, label: ele.name }))
    );
    setSelectedTownship(
      townships?.map((ele) => ({ value: ele.id, label: ele.name }))
    );
    setValue("project", { value: project?.id, label: project?.name });
    setSelectedProject({ value: project?.id, label: project?.name });
  }, [setValue, account]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updateAccount({
        id: account.id,
        name: data.name,
        role: role.value,
        township_id: selectedTownship?.map((ele) => ele.value),
        project_id: selectedProject?.value,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (success) {
      toggle(false);
    }
  }, [success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Account"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomSelect
            id="role"
            label="Select Role"
            rules={{ required: "Role is required!" }}
            control={control}
            options={roles}
            value={role}
            setData={(data) => {
              setSelectedTownship(null);
              setSelectedProject(null);
              setRole(data);
            }}
            placeholder="Select role"
          />
          {role?.value === "Field Officer" && (
            <>
              <CustomSelect
                id="project"
                label="Select Project"
                rules={{
                  required:
                    role?.value === "Field Officer"
                      ? "Project is required!"
                      : false,
                }}
                control={control}
                options={projects}
                value={selectedProject}
                setData={(data) => {
                  setSelectedTownship(null);
                  setSelectedProject(data);
                  dispatch(getTownships({ project_id: data?.value }));
                }}
                placeholder="Select project"
              />
              <CustomSelect
                id="township"
                label="Select Township"
                rules={{
                  required:
                    role?.value === "Field Officer"
                      ? "Township is required!"
                      : false,
                }}
                control={control}
                options={townships}
                value={selectedTownship}
                setData={setSelectedTownship}
                placeholder="Select township"
                isMulti={true}
              />
            </>
          )}
          {role?.value === "Programme Manager_MDR" && (
            <CustomSelect
              id="project"
              label="Select Project"
              rules={{
                required:
                  role?.value === "Programme Manager_MDR"
                    ? "Project is required!"
                    : false,
              }}
              control={control}
              options={projects}
              value={selectedProject}
              setData={setSelectedProject}
              placeholder="Select project"
            />
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default AccountUpdate;
