import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'
// react library for routing
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { Provider } from 'react-redux'
import store from 'store'
import { getToken } from 'utils/cache'
import { setCurrentUser } from 'store/reducers/auth'
import PrivateRoute from 'routers/PrivateRoute'
import AuthRoute from 'routers/AuthRoute'
import { setAccessToken } from 'services/api'
import { autoLogin } from 'store/actions/auth'
import decode from 'jwt-decode'

const token = getToken()

if (token) {
  setAccessToken(token)
  store.dispatch(autoLogin())
  store.dispatch(setCurrentUser(decode(token)))
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <AuthRoute path="/auth" component={AuthLayout} />
          <Redirect from="*" to="/auth" />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </Provider>
  )
}
export default App
