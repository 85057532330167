import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatient } from "store/actions/patient";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomSelect from "components/Inputs/CustomSelect";
import { useForm } from "react-hook-form";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { outcomes } from "variables/options";
import { currentDate } from "utils/currentDate";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { updatePatientOutcome } from "store/actions/patient";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import { yesOrNo } from "variables/options";

const PatientOutcomeEdit = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [selectedTreatmentFinished, setSelectedTreatmentFinished] =
    useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState(null);

  const dispatch = useDispatch();
  const { loading: patientLoading, patient } = useSelector(
    (state) => state.patient
  );
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const { outcome, outcome_date, remark } = patient;
    reset({
      outcome: outcome
        ? {
            value: outcome,
            label: outcome,
          }
        : null,
      outcome_date: outcome_date,
      remark: remark,
    });
    setSelectedOutcome(
      outcome
        ? {
            value: outcome,
            label: outcome,
          }
        : null
    );
  }, [reset, patient]);

  const onSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      updatePatientOutcome({
        id: patient.id,
        treatment_finished: selectedTreatmentFinished?.value,
        treatment_finished_date: values.treatment_finished_date,
        outcome_date: values.outcome_date,
        outcome: selectedOutcome?.value,
        remark: values.remark,
      })
    );

    setLoading(false);
  };

  useEffect(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  if (patientLoading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Updating Outcome</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="treatment_finished"
                    label="Treatment Finished"
                    rules={{ required: false }}
                    control={control}
                    options={yesOrNo}
                    value={selectedTreatmentFinished}
                    setData={setSelectedTreatmentFinished}
                    placeholder="Treatment Finished"
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="treatment_finished_date"
                    label="Treatment Finished Date"
                    register={{
                      ...register("treatment_finished_date", {
                        required: selectedTreatmentFinished
                          ? "Treatment Finished Date is required!"
                          : false,
                      }),
                    }}
                    placeholder="Select Treatment Finished Date"
                    errors={errors}
                    isRequired={selectedTreatmentFinished}
                    max={currentDate()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="outcome_date"
                    label="Outcome date"
                    register={{
                      ...register("outcome_date", {
                        required: false,
                      }),
                    }}
                    placeholder="Select Outcome date"
                    errors={errors}
                    isRequired={false}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="outcome"
                    label="Final Outcome"
                    rules={{ required: false }}
                    control={control}
                    options={outcomes}
                    value={selectedOutcome}
                    setData={setSelectedOutcome}
                    placeholder="Final Outcome"
                    isRequired={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CustomTextArea
                    id="remark"
                    label="Remark"
                    register={{
                      ...register("remark"),
                    }}
                    placeholder="Enter Remark"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </CardBody>
            {patientMiddleware(user?.role, UPDATE) && (
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default PatientOutcomeEdit;
