import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "services/api";
import { serverErrorMessage } from "utils/messages";
import { NotificationManager } from "react-notifications";
import { setAccessToken } from "services/api";

export const getSupportPackages = createAsyncThunk(
  'supportPackage/getSupportPackages',
  async () => {
    try {
      const result = await call('get', `support-packages`)

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getSupportPackage = createAsyncThunk("supportPackage/getSupportPackage", async (id) => {
    try {
      const response = await call("get", `support-packages/${id}`);
      const result = response.data;
  
      return result;
    } catch (error) {
      const { status, data } = error.response;
  
      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return error.message;
    }
  });

export const createSupportPackage = createAsyncThunk(
  "supportPackage/createSupportPackage",
  async (data, thunkAPI) => {
    try {
      const response = await call("post", "support-packages", data);
      const result = response.data;

      NotificationManager.success(
        "Support Package has been created successfully!"
      );
      return result;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        const obj = data.data;
        NotificationManager.error(obj[Object.keys(obj)[0]]);
      } else if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateSupportPackage = createAsyncThunk(
    "supportPackage/updateSupportPackage",
    async (data, thunkAPI) => {
      try {
        const response = await call(
          "post",
          `support-packages/${data.id}?_method=PUT`,
          data
        );
        const result = response.data;
  
        NotificationManager.success("Support Package has been updated successfully!");
        return result;
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          const obj = data.data;
          NotificationManager.error(obj[Object.keys(obj)[0]]);
        } else if (status === 401) {
          setAccessToken(null);
          NotificationManager.error(data.data.message);
        } else {
          NotificationManager.error(serverErrorMessage);
        }
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  );

  export const updateSupportPackageTownship = createAsyncThunk(
    "supportPackage/updateSupportPackageTownship",
    async (data, thunkAPI) => {
      try {
        const response = await call(
          "post",
          `support-packages-township/${data.id}?_method=PUT`,
          data
        );
        const result = response.data;
  
        NotificationManager.success("Support Package Township has been updated successfully!");
        return result;
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          const obj = data.data;
          NotificationManager.error(obj[Object.keys(obj)[0]]);
        } else if (status === 401) {
          setAccessToken(null);
          NotificationManager.error(data.data.message);
        } else {
          NotificationManager.error(serverErrorMessage);
        }
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  );

  export const recoverSupportPackage = createAsyncThunk(
    'supportPackage/recoverSupportPackage',
    async (id) => {
      try {
        await call('get', `support-packages-recover/${id}`)
  
        NotificationManager.success('Support Package has been recovered successfully!')
        return id
      } catch (error) {
        const { status, data } = error.response
  
        if (status === 401) {
          setAccessToken(null)
          NotificationManager.error(data.data.message)
        } else {
          NotificationManager.error(serverErrorMessage)
        }
        return error.message
      }
    },
  )
  
  export const temDeleteSupportPackage = createAsyncThunk(
    'supportPackage/temDeleteSupportPackage',
    async (id) => {
      try {
        await call('get', `support-packages-delete/${id}`)
  
        NotificationManager.success('Support Package has been deleted successfully!')
        return id
      } catch (error) {
        const { status, data } = error.response
  
        if (status === 401) {
          setAccessToken(null)
          NotificationManager.error(data.data.message)
        } else {
          NotificationManager.error(serverErrorMessage)
        }
        return error.message
      }
    },
  )
  
  export const deleteSupportPackage = createAsyncThunk(
    "supportPackage/deleteSupportPackage",
    async (id) => {
      try {
        await call("delete", `support-packages/${id}`);
  
        NotificationManager.success("Support Package has been deleted successfully!");
        return id;
      } catch (error) {
        const { status, data } = error.response;
  
        if (status === 401) {
          setAccessToken(null);
          NotificationManager.error(data.data.message);
        } else {
          NotificationManager.error(serverErrorMessage);
        }
        return error.message;
      }
    }
  );