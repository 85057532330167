import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomModal from "components/Modal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { createTownship } from "store/actions/township";

const TownshipCreate = ({ isOpen, toggle }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const dispatch = useDispatch();
  const { township, region, district, project } = useSelector((state) => state);
  const { success } = township;

  const regions = region.regions?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const districts = district.districts?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const projects = project.projects?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createTownship({
        name: data.name,
        region_id: data.region.value,
        district_id: data.district.value,
        project_id: data.project.value,
        abbreviation: data.abbreviation,
      })
    );
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedRegion(null);
    setSelectedDistrict(null);
    setSelectedProject(null);
    reset();
  }, [reset, setSelectedRegion, setSelectedDistrict, setSelectedProject]);

  useEffect(() => {
    if (success) {
      formReset();
    }

    return () => formReset();
  }, [success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Township"
      onClick={() => {
        toggle(false);
        formReset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="region"
            label="Select Region"
            rules={{ required: "Region is required!" }}
            control={control}
            options={regions}
            value={selectedRegion}
            setData={setSelectedRegion}
            placeholder="Select region"
          />
          <CustomSelect
            id="district"
            label="Select District"
            rules={{ required: "District is required!" }}
            control={control}
            options={districts}
            value={selectedDistrict}
            setData={setSelectedDistrict}
            placeholder="Select district"
          />
          <CustomSelect
            id="project"
            label="Select Project"
            rules={{ required: "Project is required!" }}
            control={control}
            options={projects}
            value={selectedProject}
            setData={setSelectedProject}
            placeholder="Select project"
          />
          <CustomInput
            id="name"
            label="Township Name"
            register={{
              ...register("name", {
                required: "Township Name is required!",
              }),
            }}
            placeholder="Enter township name"
            errors={errors}
          />
          <CustomInput
            id="abbreviation"
            label="Township Acronym"
            register={{
              ...register("abbreviation", {
                required: "Township Acronym is required!",
              }),
            }}
            placeholder="Enter township acronym"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default TownshipCreate;
