export const isEveryElementIncluded = (firstArray, secondArray) => {
  // Convert the first array to a Set for faster lookups
  const firstSet = new Set(firstArray);

  // Check if every element in the second array is included in the first array
  for (const element of secondArray) {
    if (!firstSet.has(element)) {
      return false;
    }
  }

  return true;
};
