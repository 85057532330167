import { createSlice } from "@reduxjs/toolkit";
import { createPfaPatient } from "store/actions/patient";
import { updatePfaPatient } from "store/actions/patient";
import { temDeletePfaPatient } from "store/actions/patient";
import { recoverPfaPatient } from "store/actions/patient";
import { deletePfaPatient } from "store/actions/patient";
import { getPfaPatient } from "store/actions/patient";
import { getPfaPatients } from "store/actions/patient";
import { updatePatientOutcome } from "store/actions/patient";
import {
  createPatient,
  getPatients,
  getPatient,
  updatePatient,
  deletePatient,
  recoverPatient,
  temDeletePatient,
} from "store/actions/patient";

const initialState = {
  loading: false,
  success: false,
  patients: [],
  patient: {},
  pfaPatients: [],
  pfaPatient: {},
  male: 0,
  female: 0,
  total: 0,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get Patients
      .addCase(getPatients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = action.payload.data;
        state.male = action.payload.male;
        state.female = action.payload.female;
        state.total = action.payload.total;
      })
      .addCase(getPatients.rejected, (state) => {
        state.loading = false;
      })

      // Create Patients
      .addCase(createPatient.pending, (state) => {
        state.success = false;
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.success = true;
        state.patients = [action.payload, ...state.patients];
        state.total = state.total + 1;
      })
      .addCase(createPatient.rejected, (state) => {
        state.success = false;
      })

      // Get Patient
      .addCase(getPatient.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(getPatient.rejected, (state) => {
        state.loading = false;
        state.success = false;
      })

      // Update Patient
      .addCase(updatePatient.pending, (state) => {
        state.success = false;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        let index = state.patients.findIndex(
          (Patient) => Patient.id === action.payload.id
        );
        state.patients[index] = action.payload;
        state.success = true;
        state.patient = action.payload;
      })
      .addCase(updatePatient.rejected, (state) => {
        state.success = false;
      })

      // Update Patient Outcome
      .addCase(updatePatientOutcome.pending, (state) => {
        state.success = false;
      })
      .addCase(updatePatientOutcome.fulfilled, (state, action) => {
        let index = state.patients.findIndex(
          (Patient) => Patient.id === action.payload.id
        );
        state.patients[index] = action.payload;
        state.success = true;
        state.patient = action.payload;
      })
      .addCase(updatePatientOutcome.rejected, (state) => {
        state.success = false;
      })

      // Delete Patient
      .addCase(deletePatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(deletePatient.rejected, (state) => {
        state.loading = false;
      })

      // Temp Delete Patient
      .addCase(temDeletePatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(temDeletePatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(temDeletePatient.rejected, (state) => {
        state.loading = false;
      })

      // Recover Patient
      .addCase(recoverPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(recoverPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(recoverPatient.rejected, (state) => {
        state.loading = false;
      })

      // Get PfaPatients
      .addCase(getPfaPatients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPfaPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.pfaPatients = action.payload.data;
        state.male = action.payload.male;
        state.female = action.payload.female;
        state.total = action.payload.total;
      })
      .addCase(getPfaPatients.rejected, (state) => {
        state.loading = false;
      })

      // Create PfaPatients
      .addCase(createPfaPatient.pending, (state) => {
        state.success = false;
      })
      .addCase(createPfaPatient.fulfilled, (state, action) => {
        state.success = true;
        state.pfaPatients = [action.payload, ...state.pfaPatients];
        state.total = state.total + 1;
      })
      .addCase(createPfaPatient.rejected, (state) => {
        state.success = false;
      })

      // Get Patient
      .addCase(getPfaPatient.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(getPfaPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.pfaPatient = action.payload;
      })
      .addCase(getPfaPatient.rejected, (state) => {
        state.loading = false;
        state.success = false;
      })

      // Update PfaPatient Outcome
      .addCase(updatePfaPatient.pending, (state) => {
        state.success = false;
      })
      .addCase(updatePfaPatient.fulfilled, (state, action) => {
        let index = state.pfaPatients.findIndex(
          (Patient) => Patient.id === action.payload.id
        );
        state.pfaPatients[index] = action.payload;
        state.success = true;
        state.pfaPatient = action.payload;
      })
      .addCase(updatePfaPatient.rejected, (state) => {
        state.success = false;
      })

      // Delete Patient
      .addCase(deletePfaPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePfaPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.pfaPatients = state.pfaPatients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(deletePfaPatient.rejected, (state) => {
        state.loading = false;
      })


      // Temp Delete Pfa Patient
      .addCase(temDeletePfaPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(temDeletePfaPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.pfaPatients = state.pfaPatients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(temDeletePfaPatient.rejected, (state) => {
        state.loading = false;
      })

      // Recover Patient
      .addCase(recoverPfaPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(recoverPfaPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.pfaPatients = state.pfaPatients.filter(
          (patient) => patient.id !== action.payload
        );
        state.total = state.total - 1;
      })
      .addCase(recoverPfaPatient.rejected, (state) => {
        state.loading = false;
      })
  },
});

export default patientSlice.reducer;
