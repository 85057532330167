// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";

import styles from "./AdminNavbar.module.css";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "store/actions/auth";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const router = useHistory();

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          styles.navbar
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            {/* <Nav className="align-items-center" navbar>
              <img
                src={require("assets/img/brand/the_union.png").default}
                alt="The Union"
                style={{ backgroundColor: "#FFFFFF" }}
                width={230}
              />
            </Nav> */}
            <Nav className="align-items-center " navbar>
              <img
                src={require("assets/img/brand/the_union.png").default}
                alt="The Union"
                style={{ backgroundColor: "#FFFFFF" }}
                width={230}
              />
            </Nav>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className={classnames("sidenav-toggler-inner")}>
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <img
                src={require("assets/img/brand/USAID.jpg").default}
                style={{ backgroundColor: "#fff" }}
                alt="The Union"
                width={230}
              />
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  {/* <Media className="align-items-center">
                    <span className="avatar avatar-md rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/brand/person.png").default}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span
                        className={classnames(
                          "mb-0 text-md font-weight-bold",
                          styles.account_name
                        )}
                      >
                        {user.name}
                      </span>
                    </Media>
                  </Media> */}
                  <Button size="sm" color="danger">
                      <i className="ni ni-settings-gear-65" />
                    </Button>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-single-02" />
                    <span>{user.name}</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-app" />
                    <span>{user.role}</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      router.push("/admin/change-password");
                    }}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>Change Password</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(logout());
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
