import { createAsyncThunk } from "@reduxjs/toolkit";
import { call } from "services/api";
import { serverErrorMessage } from "utils/messages";
import { NotificationManager } from "react-notifications";
import { setAccessToken } from "services/api";

export const getReport = createAsyncThunk("report/getReport", async (query) => {
  try {
    const result = await call(
      "get",
      `SPS-report?${new URLSearchParams(query).toString()}`
    );
    return result.data;
  } catch (error) {
    const { status, data } = error.response;

    if (status === 401) {
      setAccessToken(null);
      NotificationManager.error(data.data.message);
    } else {
      NotificationManager.error(serverErrorMessage);
    }
    return error.message;
  }
});

export const getCPIReport = createAsyncThunk("report/getCPIReport", async (query) => {
  try {
    const result = await call(
      "get",
      `CPI-report?${new URLSearchParams(query).toString()}`
    );
    return result.data;
  } catch (error) {
    const { status, data } = error.response;

    if (status === 401) {
      setAccessToken(null);
      NotificationManager.error(data.data.message);
    } else {
      NotificationManager.error(serverErrorMessage);
    }
    return error.message;
  }
});
