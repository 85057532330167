export const CPIReportTitle = {
  1: "No. packages supported for specialist consultation (package 1)",
  2: "No. packages supported for Hearing aid support (package 2)",
  3: "No. packages supported for Palliative care support (package 3)",
  4: "No. packages supported for Hospitalization support (package 4)",
  5: "No. packages supported for DR-TB hero who completed the treatment (package 5)",
  6: "No. packages supported for Housing support for initial treatment period (package 6)",
  7: "No. packages supported for Pre-enrollment support for investigatioin and travel cost from hard to reach area to treatment centre (package 7)",
  8: "No. packages supported for Nutritional support (package 8)",
  9: "No. packages supported for follow-up/referral (package 9)"
};
