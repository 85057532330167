import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getDistricts = createAsyncThunk(
  'district/getDistricts',
  async () => {
    try {
      const response = await call('get', 'districts')
      const result = response.data

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getDistrict = createAsyncThunk(
  'district/getDistrict',
  async (id) => {
    try {
      const response = await call('get', `districts/${id}`)
      const result = response.data

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const createDistrict = createAsyncThunk(
  'district/createDistrict',
  async (data) => {
    try {
      const response = await call('post', 'districts', data)
      const result = response.data

      NotificationManager.success('District has been created successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const updateDistrict = createAsyncThunk(
  'district/updateDistrict',
  async (data) => {
    try {
      const response = await call(
        'post',
        `districts/${data.id}?_method=PUT`,
        data,
      )
      const result = response.data

      NotificationManager.success('District has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const deleteDistrict = createAsyncThunk(
  'district/deleteDistrict',
  async (id) => {
    try {
      await call('delete', `districts/${id}`)

      NotificationManager.success('District has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)
