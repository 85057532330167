import { createSlice } from '@reduxjs/toolkit'
import {
  createDistrict,
  getDistricts,
  getDistrict,
  updateDistrict,
  deleteDistrict,
} from 'store/actions/district'

const initialState = {
  loading: false,
  success: false,
  districts: [],
  district: {},
}

const districtSlice = createSlice({
  name: 'district',
  initialState,
  extraReducers: (builder) => {
    // Get districts
    builder.addCase(getDistricts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDistricts.fulfilled, (state, action) => {
      state.loading = false
      state.districts = action.payload
    })
    builder.addCase(getDistricts.rejected, (state) => {
      state.loading = false
    })

    // Create districts
    builder.addCase(createDistrict.pending, (state) => {
      state.success = false
    })
    builder.addCase(createDistrict.fulfilled, (state, action) => {
      state.success = true
      state.districts = [action.payload, ...state.districts]
    })
    builder.addCase(createDistrict.rejected, (state) => {
      state.success = false
    })

    // Get district
    builder.addCase(getDistrict.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getDistrict.fulfilled, (state, action) => {
      state.loading = false
      state.district = action.payload
    })
    builder.addCase(getDistrict.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update district
    builder.addCase(updateDistrict.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateDistrict.fulfilled, (state, action) => {
      let index = state.districts.findIndex(
        (district) => district.id === action.payload.id,
      )
      state.districts[index] = action.payload
      state.success = true
      state.district = action.payload
    })
    builder.addCase(updateDistrict.rejected, (state) => {
      state.success = false
    })

    // Delete district
    builder.addCase(deleteDistrict.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteDistrict.fulfilled, (state, action) => {
      state.loading = false
      state.districts = state.districts.filter(
        (district) => district.id !== action.payload,
      )
    })
    builder.addCase(deleteDistrict.rejected, (state) => {
      state.loading = false
    })
  },
})

export default districtSlice.reducer
