import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BackBtn from "utils/backBtn";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "./index.module.css";

const Dashboard = () => {
  return (
    <>
      <Container className="mt-3">
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Criteria</h3>
              </Col>
              <Col xs="6">
                <ReactHTMLTableToExcel
                  className="btn btn-success btn-sm"
                  table="table-to-xls"
                  filename={"Criteria"}
                  sheet="Criteria"
                  buttonText="Export"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <table id="table-to-xls" className={styles.styled_table}>
              <thead>
                <tr>
                  <th colSpan={2} scope="col">
                    Criteria and Necessary Information for Social Protection
                    Support for DR TB patients
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    Covered Townships- Mandalay (Mandalay Downtown 7 Townships,
                    Myingyan, Meikhtila, Taungtha, NyaungOo, Kyaukpadaung,
                    Moegoke, Singu, Pyinoolwin), Sagaing (Monywa, Shwebo, Katha,
                    Kalay, Tamu), Shan( Taunggyi, Kalaw, Lashio, Muse)
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td className={styles.primary}>
                    Support for specialist consultation
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>အထူးကုဆရာဝန်နှင့် ပြသရန် ထောက်ပံ့ငွေ</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Maximum 5 times throughout treatment course (maximum 30,000
                    MMK)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td className={styles.primary}>Hearing aid support</td>
                </tr>
                <tr>
                  <td></td>
                  <td>အကြားအာရုံထောက်ပံ့ကိရိယာအတွက် ထောက်ပံ့ငွေ</td>
                </tr>
                <tr>
                  <td></td>
                  <td>One time support (maximum 150,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td className={styles.primary}>Palliative care support</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Palliative care အတွက် ထောက်ပံ့ငွေ</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Maximum 3 months (maximum 30,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td className={styles.primary}>Hospitalization support</td>
                </tr>
                <tr>
                  <td></td>
                  <td>ဆေးရုံတက်ရောက်ကုသမှုစရိတ်</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Maximum 2 times (maximum 100,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>5</td>
                  <td className={styles.primary}>
                    DR-TB hero who completed the treatment
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>ဆေးယဉ်ပါးတီဘီကုသမှု ပြီးဆုံးသည့်အတွက် ချီးမြင့်ငွေ</td>
                </tr>
                <tr>
                  <td></td>
                  <td>One time support (maximum 20,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>6</td>
                  <td className={styles.primary}>
                    Housing support for initial treatment period
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>ကနဦးဆေးကုကာလအတွင်း အိမ်ငှားစရိတ်ထောက်ပံ့ခြင်း</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Maximum 6 months (maximum 50,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>7</td>
                  <td className={styles.primary}>
                    Pre-enrollment support for investigatioin and travel cost
                    from hard to reach area to treatment centre
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    ဆေးယဥ်ပါးတီဘီဆေးကုသမှုမခံယူမီ လိုအပ်သော
                    စစ်ဆေးမှုများပြုလုပ်ရန် (သို့) သွားလာရခက်ခဲသောဒေသများမှ
                    ကုသရေးဌာနသို့ ခရီးစရိတ်
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>One time support (maximum 150,000 MMK)</td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>8</td>
                  <td className={styles.primary}>Nutritional support</td>
                </tr>
                <tr>
                  <td></td>
                  <td>အာဟာရထောက်ပံ့ငွေ</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Stop support if BMI{">"}18.5 2 consecutive months (maximum
                    30,000 MMK)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>9</td>
                  <td className={styles.primary}>
                    Support for follow-up/referral
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>ရက်ချိန်းပြန်လည်ပြသရန်/လူနာလွှဲပြောင်းရန် စရိတ်</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Maximum 4 times (maximum 50,000 MMK)</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Dashboard;
