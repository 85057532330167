import { createSlice } from "@reduxjs/toolkit";
import { deleteSupportPackage } from "store/actions/supportPackage";
import { temDeleteSupportPackage } from "store/actions/supportPackage";
import { recoverSupportPackage } from "store/actions/supportPackage";
import { getSupportPackages } from "store/actions/supportPackage";
import { updateSupportPackage } from "store/actions/supportPackage";
import { updateSupportPackageTownship } from "store/actions/supportPackage";
import { getSupportPackage } from "store/actions/supportPackage";
import { createSupportPackage } from "store/actions/supportPackage";

const initialState = {
  loading: false,
  success: false,
  supportPackage: {},
  supportPackages: [],
};

const supportPackageSlice = createSlice({
  name: "supportPackage",
  initialState,
  extraReducers: (builder) => {
    // Get packages
    builder.addCase(getSupportPackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSupportPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.supportPackages = action.payload.data;
      state.total = action.payload.total;
    });
    builder.addCase(getSupportPackages.rejected, (state) => {
      state.loading = false;
    });

    // Create Support Packages
    builder.addCase(createSupportPackage.pending, (state) => {
      state.success = false;
    });
    builder.addCase(createSupportPackage.fulfilled, (state) => {
      state.success = true;
    });
    builder.addCase(createSupportPackage.rejected, (state) => {
      state.success = false;
    });

    // Get Support Package
    builder.addCase(getSupportPackage.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getSupportPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.supportPackage = action.payload;
    });
    builder.addCase(getSupportPackage.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    // Update Support Package
    builder.addCase(updateSupportPackage.pending, (state) => {
      state.success = false;
    });
    builder.addCase(updateSupportPackage.fulfilled, (state, action) => {
      state.success = true;
      state.supportPackage = action.payload;
    });
    builder.addCase(updateSupportPackage.rejected, (state) => {
      state.success = false;
    });

     // Update Support Package Township
     builder.addCase(updateSupportPackageTownship.pending, (state) => {
      state.success = false;
    });
    builder.addCase(updateSupportPackageTownship.fulfilled, (state, action) => {
      state.success = true;
      state.supportPackage = action.payload;
    });
    builder.addCase(updateSupportPackageTownship.rejected, (state) => {
      state.success = false;
    });

    // Delete Support Package
    builder.addCase(deleteSupportPackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSupportPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.supportPackages = state.supportPackages.filter(
        (p) => p.id !== action.payload
      );
      state.total = state.total - 1;
    });
    builder.addCase(deleteSupportPackage.rejected, (state) => {
      state.loading = false;
    });

    // Temp Delete Support Package
    builder.addCase(temDeleteSupportPackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(temDeleteSupportPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.supportPackages = state.supportPackages.filter(
        (p) => p.id !== action.payload
      );
      state.total = state.total - 1;
    });
    builder.addCase(temDeleteSupportPackage.rejected, (state) => {
      state.loading = false;
    });

    // Recover Support Package
    builder.addCase(recoverSupportPackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recoverSupportPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.supportPackages = state.supportPackages.filter((p) => p.id !== action.payload);
      state.total = state.total - 1;
    });
    builder.addCase(recoverSupportPackage.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default supportPackageSlice.reducer;
