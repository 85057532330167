import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AuthRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth)

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/dashboard" />
        )
      }
    />
  )
}

export default AuthRoute
