import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { sex, years, treatmentRegimens } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTownships } from "store/actions/township";
import { createPatient } from "store/actions/patient";
import { useCallback } from "react";
import { getPatients } from "store/actions/patient";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { calculateBMI } from "utils/BMI";
import { NotificationManager } from "react-notifications";
import CustomTable from "components/Table/Table";
import { calculateAge } from "utils/calculateAge";
import { yesOrNo } from "variables/options";

const householdMemberInitial = {
  name: "",
  age: "",
};

const PatientCreate = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedTreatmentRegimen, setSelectedTreatmentRegimen] =
    useState(null);
  const [BMI, setBMI] = useState(0);
  const [selectedHouseholdMember, setSelectedHouseholdMember] = useState(
    householdMemberInitial
  );
  const [selectedHouseholdMembers, setSelectedHouseholdMembers] = useState([]);
  const [
    selectedDiedBeforeTreatmentEnrollment,
    setSelectedDiedBeforeTreatmentEnrollment,
  ] = useState({ value: "No", label: "No" });

  const { townships } = useSelector((state) => state.township);
  const { total, success } = useSelector((state) => state.patient);

  useEffect(() => {
    dispatch(getTownships());
    dispatch(getPatients());
  }, [dispatch]);

  const townshipOptions = townships?.map((township) => {
    return { value: township.id, label: township.name };
  });

  const handleAddHouseholdMember = () => {
    const { name, age } = selectedHouseholdMember;
    if (name && age) {
      setSelectedHouseholdMembers((prev) => [...prev, selectedHouseholdMember]);
      setSelectedHouseholdMember(householdMemberInitial);
    } else {
      NotificationManager.warning(
        "Please enter name and age of household contact!"
      );
    }
  };

  const handleDeleteHouseholdMember = (index) => {
    const filterHouseholdMembers = selectedHouseholdMembers.filter(
      (_, i) => i !== index
    );
    setSelectedHouseholdMembers(filterHouseholdMembers);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    // if (selectedHouseholdMembers.length > 12) {
    //   NotificationManager.warning("Too many household contacts!");
    // } else {
    await dispatch(
      createPatient({
        year: selectedYear?.value,
        township_id: selectedTownship?.value,
        RR_code: values.RR_code,
        DRTB_code: values.DRTB_code,
        SP_code: values.SP_code,
        name: values.name,
        DOB: values.DOB,
        age: values.age,
        sex: selectedSex?.value,
        died_before_treatment_enrollment:
          selectedDiedBeforeTreatmentEnrollment?.value,
        treatment_start_date:
          selectedDiedBeforeTreatmentEnrollment?.value === "No"
            ? values.treatment_start_date
            : null,
        treatment_regimen:
          selectedDiedBeforeTreatmentEnrollment?.value === "No"
            ? selectedTreatmentRegimen?.value
            : null,
        treatment_regimen_other: values.treatment_regimen_other,
        patient_address: values.patient_address,
        patient_phone_no: values.patient_phone_no,
        contact_info: values.contact_info,
        contact_phone_no: values.contact_phone_no,
        primary_language: values.primary_language,
        secondary_language: values.secondary_language,
        height: values.height,
        weight: values.weight,
        BMI: values.BMI,
        household_contacts: selectedHouseholdMembers,
      })
    );
    // }

    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedYear(null);
    setSelectedTownship(null);
    setSelectedSex(null);
    setSelectedTreatmentRegimen(null);
    setSelectedHouseholdMembers([]);
    setSelectedDiedBeforeTreatmentEnrollment(null);
    reset();
  }, [reset]);

  useEffect(() => {
    if (success) {
      formReset();
    }

    return () => formReset();
  }, [success, formReset]);

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>Create Patient</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="year"
                  label="Year"
                  rules={{ required: "Year is required!" }}
                  control={control}
                  options={years}
                  value={selectedYear}
                  setData={setSelectedYear}
                  placeholder="Year"
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <CustomSelect
                  id="township"
                  label="Township"
                  rules={{ required: "Township is required!" }}
                  control={control}
                  options={townshipOptions}
                  value={selectedTownship}
                  setData={setSelectedTownship}
                  placeholder="Township"
                />
              </Col>
              <Col md={3}>
                <CustomInput
                  id="RR_code"
                  label="Lab Code"
                  register={{
                    ...register("RR_code", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter Lab Code"
                  errors={errors}
                  isRequired={false}
                  onChange={() => {
                    setValue("DRTB_code", "");
                  }}
                />
              </Col>
              <Col md={3}>
                <CustomInput
                  id="DRTB_code"
                  label="DRTB Code"
                  register={{
                    ...register("DRTB_code", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter DRTB Code"
                  errors={errors}
                  isRequired={false}
                  onChange={() => {
                    setValue("RR_code", "");
                  }}
                />
              </Col>
              <Col md={3}>
                <CustomInput
                  id="SP_code"
                  label={`SP Code (Last SP Code is ${total})`}
                  register={{
                    ...register("SP_code", {
                      required: "SP Code is required!",
                    }),
                  }}
                  placeholder="Enter SP Code"
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <CustomInput
                  id="name"
                  label="Name"
                  register={{
                    ...register("name", {
                      required: "Name is required!",
                    }),
                  }}
                  placeholder="Enter Name"
                  errors={errors}
                />
              </Col>
              <Col md={3}>
                <CustomDateInput
                  id="DOB"
                  label="DOB"
                  register={{
                    ...register("DOB", {
                      required: false,
                    }),
                  }}
                  placeholder="Select DOB"
                  errors={errors}
                  isRequired={false}
                  max={currentDate()}
                  onChange={(event) => {
                    const date = event.target.value;
                    setValue("age", calculateAge(date));
                  }}
                />
              </Col>
              <Col md={3}>
                <CustomNumberInput
                  id="age"
                  label="Age"
                  register={{
                    ...register("age", {
                      required: "Age is required!",
                    }),
                  }}
                  placeholder="Enter age"
                  errors={errors}
                  max={120}
                />
              </Col>
              <Col md={3}>
                <CustomSelect
                  id="sex"
                  label="Sex"
                  rules={{ required: "Sex is required!" }}
                  control={control}
                  options={sex}
                  value={selectedSex}
                  setData={setSelectedSex}
                  placeholder="Sex"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="died_before_treatment_enrollment"
                  label="Died Before Treatment Enrollment"
                  rules={{ required: false }}
                  control={control}
                  options={yesOrNo}
                  value={selectedDiedBeforeTreatmentEnrollment}
                  setData={setSelectedDiedBeforeTreatmentEnrollment}
                  placeholder="Died Before Treatment Enrollment"
                />
              </Col>
            </Row>

            {selectedDiedBeforeTreatmentEnrollment?.value === "No" && (
              <Row>
                <Col md={3}>
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start Date"
                    register={{
                      ...register("treatment_start_date", {
                        required: "Treatment Start Date is required",
                      }),
                    }}
                    placeholder="Select Treatment Start Date"
                    errors={errors}
                    max={currentDate()}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="treatment_regimen"
                    label="Treatment Regimen"
                    rules={{ required: "Treatment Regimen is required!" }}
                    control={control}
                    options={treatmentRegimens}
                    value={selectedTreatmentRegimen}
                    setData={setSelectedTreatmentRegimen}
                    placeholder="Treatment Regimen"
                  />
                </Col>
                {selectedTreatmentRegimen?.value === "9. Other" && (
                  <Col md={5}>
                    <CustomInput
                      id="treatment_regimen_other"
                      label="Other"
                      register={{
                        ...register("treatment_regimen_other", {
                          required:
                            selectedTreatmentRegimen?.value === "9. Other"
                              ? "Other is required!"
                              : false,
                        }),
                      }}
                      placeholder="Enter Other"
                      errors={errors}
                    />
                  </Col>
                )}
              </Row>
            )}

            <Row>
              <Col md={7}>
                <CustomInput
                  id="patient_address"
                  label="Patient Address"
                  register={{
                    ...register("patient_address", {
                      required: "Patient Address is required!",
                    }),
                  }}
                  placeholder="Enter Patient Address"
                  errors={errors}
                />
              </Col>
              <Col md={5}>
                <CustomInput
                  id="patient_phone_no"
                  label="Patient Phone No"
                  register={{
                    ...register("patient_phone_no", {
                      required: "Patient Phone No is required!",
                      pattern: {
                        value: /^[0-9]{3,11}$/,
                        message: "Invalid Phone Number",
                      },
                    }),
                  }}
                  placeholder="Enter Patient Phone No"
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <CustomInput
                  id="contact_info"
                  label="Contact Info"
                  register={{
                    ...register("contact_info", {
                      required: "Contact Info is required!",
                    }),
                  }}
                  placeholder="Enter Contact Info"
                  errors={errors}
                />
              </Col>
              <Col md={5}>
                <CustomInput
                  id="contact_phone_no"
                  label="Contact Phone No"
                  register={{
                    ...register("contact_phone_no", {
                      required: "Contact Phone No is required!",
                      pattern: {
                        value: /^[0-9]{3,11}$/,
                        message: "Invalid Phone Number",
                      },
                    }),
                  }}
                  placeholder="Enter Contact Phone No"
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomInput
                  id="primary_language"
                  label="Primary Language"
                  register={{
                    ...register("primary_language", {
                      required: "Primary Language is required!",
                    }),
                  }}
                  placeholder="Enter Primary Language"
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="secondary_language"
                  label="Secondary Language"
                  register={{
                    ...register("secondary_language", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter Secondary Language"
                  errors={errors}
                  isRequired={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomNumberInput
                  id="height"
                  label="Height(cm)"
                  register={{
                    ...register("height", {
                      required: "Height(cm) is required!",
                    }),
                  }}
                  placeholder="Enter height(cm)"
                  errors={errors}
                  onChange={(event) => {
                    let height = event.target.value * 0.01;
                    let weight = getValues("weight");

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                  max={251}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="weight"
                  label="Initial Body Weight(kg)"
                  register={{
                    ...register("weight", {
                      required: "Initial Body Weight(kg) is required!",
                    }),
                  }}
                  placeholder="Enter weight(Kg)"
                  errors={errors}
                  onChange={(event) => {
                    let weight = event.target.value;
                    let height = getValues("height") * 0.01;

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                  max={635}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="BMI"
                  label={`Initial BMI ${calculateBMI(parseFloat(BMI))}`}
                  register={{
                    ...register("BMI", {
                      required: "Initial BMI is required!",
                    }),
                  }}
                  placeholder="Enter Initial BMI"
                  errors={errors}
                  disabled={true}
                />
              </Col>
            </Row>

            <h3>Household Contacts</h3>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <label htmlFor="household_member_name">Name</label>
                  <input
                    id="household_member_name"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    value={selectedHouseholdMember.name}
                    onChange={(event) => {
                      setSelectedHouseholdMember((prev) => ({
                        ...prev,
                        name: event.target.value,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <label htmlFor="household_member_age">Age</label>
                  <input
                    id="household_member_age"
                    className="form-control"
                    placeholder="Enter Age"
                    type="number"
                    value={selectedHouseholdMember.age}
                    onChange={(event) => {
                      setSelectedHouseholdMember((prev) => ({
                        ...prev,
                        age: event.target.value,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <Button
                  color="success"
                  size="sm"
                  onClick={handleAddHouseholdMember}
                  className="mt-5"
                >
                  Add
                </Button>
              </Col>
            </Row>
            <CustomTable
              header={
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Age</th>
                  <th scope="col">Delete</th>
                </tr>
              }
              body={selectedHouseholdMembers?.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.age}</td>
                  <td>
                    <Button
                      onClick={() => handleDeleteHouseholdMember(index)}
                      color="danger"
                      size="sm"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            />
          </CardBody>
          <CardFooter className="text-right">
            <Button color="primary" size="sm" disabled={loading} type="submit">
              {loading ? "Loading" : "Save"}
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};
export default PatientCreate;
