import { call } from "services/api";

export const patientExport = async (query) => {
  const response = await call(
    "get",
    `patients-export?${new URLSearchParams(query).toString()}`
  );
  const result = response.data;

  return { response, result };
};
