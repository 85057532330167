import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatients } from "store/actions/patient";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import Select from "react-select";
import { getTownships } from "store/actions/township";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { patientMiddleware, EXPORT, CREATE } from "utils/middleware";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { patientExport } from "utils/patientExport";
import { call } from "services/api";
import { currentDate } from "utils/currentDate";
import { months } from "variables/options";
import PackageExportCustomPagination from "./PackageExportCustomPagination";
import { packageNo } from "variables/options";
import { years } from "variables/options";

const PatientList = () => {
  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [allPackageExportLoading, setAllPackageExportLoading] = useState(false);
  const [pfaPatientExportLoading, setPfaPatientExportLoading] = useState(false);

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [townshipDRTBCode, setTownshipDRTBCode] = useState("");

  // Package UI Export
  const [packageExportLoading, setPackageExportLoading] = useState(false);
  const [packageExportStartYear, setPackageExportStartYear] = useState(null);
  const [packageExportEndYear, setPackageExportEndYear] = useState(null);
  const [packageExportNo, setPackageExportNo] = useState(null);

  // Package UI Show
  const [packageExportUILoading, setPackageExportUILoading] = useState(false);
  const [packageTitles, setPackageTitles] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [packageExportTotal, setPackageExportTotal] = useState(0);
  const [packageExportCurrentPage, setPackageExportCurrentPage] = useState(0);
  const [packageExportUIs, setPackageExportUIs] = useState([]);

  const { patients, male, female, total } = useSelector(
    (state) => state.patient
  );
  const township = useSelector((state) => state.township);
  const loading = useSelector((state) => state.patient.loading);
  const user = useSelector((state) => state.auth);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPatients(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    if (selectedTownship) {
      query.current_township_id = selectedTownship.value;
    } else {
      delete query["current_township_id"];
    }

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.SP_code = code;
    } else {
      delete query["SP_code"];
    }
    if (townshipDRTBCode !== "") {
      query.unique_id = townshipDRTBCode;
    } else {
      delete query["unique_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleExport = async ({ with_details = false }) => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);

      query.with_details = with_details === true ? 1 : 0;
      const { response, result } = await patientExport(query);

      let fileName = `All-Patients ${with_details ? "with details" : ""}`;
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleAllPackageExport = async () => {
    setAllPackageExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);

      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      const response = await call(
        "get",
        `all-support-packages-export?${new URLSearchParams(query).toString()}`
      );

      const result = response.data;

      let fileName = "All-Package";
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Please try again!");
    }
    setAllPackageExportLoading(false);
  };

  const handlePackageExport = async () => {
    setPackageExportLoading(true);
    if (packageExportStartYear && packageExportEndYear && packageExportNo) {
      if (packageExportStartYear.value <= packageExportEndYear.value) {
        try {
          const query = queryString.parse(router.location.search);

          query.start_year = packageExportStartYear.value;
          query.end_year = packageExportEndYear.value;
          query.package_no = packageExportNo.value;

          let titles = months?.map(
            (month) => packageExportStartYear.value + "-" + month.label
          );
          if (packageExportStartYear.value < packageExportEndYear.value) {
            titles = titles.concat(
              months?.map(
                (month) => packageExportEndYear.value + "-" + month.label
              )
            );
          }

          const response = await call(
            "get",
            `support-packages-export?${new URLSearchParams(query).toString()}`
          );
          const result = response.data?.map((data, index) => {
            const dynamicFields = titles.reduce(
              (acc, title) => ({
                ...acc,
                [title]: data[title],
              }),
              {}
            );

            return {
              No: index + 1,
              Township: data.township,
              TO_status: data.TO_status,
              TO_township: data.TO_township,
              Name: data.name,
              SP_code: data.SP_code,
              ...dynamicFields,
              Remark: data.remark,
            };
          });

          let fileName = `Packages-${packageExportNo.value}-${packageExportStartYear.value}-${packageExportEndYear.value}`;
          if (selectedTownship) {
            fileName += `-${selectedTownship.label}`;
          }

          if (response.status === "success") {
            excelExport(result, fileName);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select year and package no!");
    }
    setPackageExportLoading(false);
  };

  const handlePackageExportUI = async () => {
    setPackageExportUILoading(true);
    if (packageExportStartYear && packageExportEndYear && packageExportNo) {
      if (packageExportStartYear.value <= packageExportEndYear.value) {
        try {
          const query = queryString.parse(router.location.search);

          query.start_year = packageExportStartYear.value;
          query.end_year = packageExportEndYear.value;
          query.package_no = packageExportNo.value;

          // let titles = [];
          // for (const key in monthDictionary) {
          //   let title =
          //     packageExportStartYear.value + "-" + monthDictionary[key];
          //   titles.push(title);
          // }
          let titles = months?.map(
            (month) => packageExportStartYear.value + "-" + month.label
          );

          if (packageExportStartYear.value < packageExportEndYear.value) {
            // for (const key in monthDictionary) {
            //   let title =
            //     packageExportEndYear.value + "-" + monthDictionary[key];
            //   titles.push(title);
            // }
            titles = titles.concat(
              months?.map(
                (month) => packageExportEndYear.value + "-" + month.label
              )
            );
          }
          setPackageTitles(titles);

          const response = await call(
            "get",
            `support-packages-export?${new URLSearchParams(query).toString()}`
          );

          if (response.status === "success") {
            let result = response.data;

            setPackageExportTotal(result.length);
            setPackageExportUIs(result);
            setPackageExportCurrentPage(1);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select year and package no!");
    }
    setPackageExportUILoading(false);
    setToggle(false);
  };

  const startIndex = (packageExportCurrentPage - 1) * paginationLimit;
  const endIndex = startIndex + paginationLimit;
  const packageExportUIData = packageExportUIs.slice(startIndex, endIndex);

  if (loading) {
    return <FullScreenLoading />;
  }

  const showPatientListButton = () => {
    if (toggle) {
      return true;
    }
    if (packageExportUIs.length > 0) {
      return true;
    }
    return false;
  };

  const handlePfaPatientExport = async () => {
    setPfaPatientExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      console.log(selectedTownship?.value);
      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      if (selectedTownship) query.township_id = selectedTownship.value;

      const response = await call(
        "get",
        `pfa-patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      let fileName = "Pfa-Patient";
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setPfaPatientExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Patients</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button size="sm" color="primary" onClick={handleFilter}>
                  <i className="fa fa-search" />
                </Button>

                {patientMiddleware(user?.user?.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push(`/admin/create-patient`)}
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <>
                    <Button
                      size="sm"
                      color="info"
                      onClick={handleExport}
                      disabled={exportLoading}
                    >
                      {exportLoading ? "Loading" : "Patient Export"}
                    </Button>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => handleExport({ with_details: true })}
                      disabled={exportLoading}
                    >
                      {exportLoading ? "Loading" : "Patient Details Export"}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search SP Code..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={townshipDRTBCode}
                  onChange={(event) => setTownshipDRTBCode(event.target.value)}
                  placeholder="Search Township DRTB Code"
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={8}>
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleAllPackageExport}
                    disabled={allPackageExportLoading}
                    className="mt-5"
                  >
                    {allPackageExportLoading ? "Loading" : "All Package Export"}
                  </Button>
                )}
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePfaPatientExport}
                  disabled={pfaPatientExportLoading}
                  className="mt-5"
                >
                  {pfaPatientExportLoading ? "Loading" : "PfaPatient Export"}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Male = {male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Female = {female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Total = {total}
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>
                <Select
                  options={packageNo}
                  value={packageExportNo}
                  onChange={(value) => setPackageExportNo(value)}
                  placeholder="Select package no"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportStartYear}
                  onChange={(value) => setPackageExportStartYear(value)}
                  placeholder="Start year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportEndYear}
                  onChange={(value) => setPackageExportEndYear(value)}
                  placeholder="End year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={5}>
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePackageExport}
                  disabled={packageExportLoading}
                  className="mt-2"
                >
                  {packageExportLoading ? "Loading" : "Package Export"}
                </Button>
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePackageExportUI}
                  disabled={packageExportUILoading}
                  className="mt-2"
                >
                  {packageExportUILoading ? "Loading" : "Package UI"}
                </Button>
                {showPatientListButton() && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setToggle((prev) => !prev)}
                    className="mt-2"
                  >
                    {!toggle ? "Show Patient List" : "Show Package UI"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          {toggle ? (
            <>
              <CustomTable
                header={
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">SP Code</th>
                    <th scope="col">PFA</th>
                    <th scope="col">Action</th>
                    <th scope="col">TSP</th>
                    <th scope="col">RR Code</th>
                    <th scope="col">DRTB Code</th>
                    <th scope="col">Township DRTB Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Age</th>
                    <th scope="col">Sex</th>
                  </tr>
                }
                body={patients?.map((row, index) => (
                  <tr key={row.id}>
                    <td>{index + 1}</td>
                    <td>{row.SP_code}</td>
                    {row.township?.abbreviation === "PGT" ||
                    row.township?.abbreviation === "TGI" ? (
                      <td>
                        <Button
                          size="sm"
                          color="info"
                          onClick={() =>
                            router.push(`/admin/patient-pfa-list/${row.id}`)
                          }
                        >
                          PFA
                        </Button>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      <Button
                        size="sm"
                        color="success"
                        onClick={() =>
                          router.push(`/admin/patient-detail/${row.id}`)
                        }
                      >
                        Detail
                      </Button>
                    </td>
                    <td>{row.township?.abbreviation}</td>
                    <td>{row.RR_code}</td>
                    <td>{row.DRTB_code}</td>
                    <td>{row.unique_id}</td>
                    <td>{row.name}</td>
                    <td>{row.age}</td>
                    <td>{row.sex}</td>
                  </tr>
                ))}
              />
              {total > paginationLimit && (
                <CustomPagination pageCount={total / paginationLimit} />
              )}
            </>
          ) : (
            <>
              <CustomTable
                header={
                  <tr>
                    <th scope="col">Township</th>
                    <th scope="col">TO Status</th>
                    <th scope="col">TO Township</th>
                    <th scope="col">Name</th>
                    <th scope="col">SP Code</th>
                    {packageTitles?.map((title, index) => (
                      <th key={index}>{title}</th>
                    ))}
                    <th scope="col">Remark</th>
                  </tr>
                }
                body={packageExportUIData?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.township}</td>
                    <td>{row.TO_status}</td>
                    <td>{row.TO_township}</td>
                    <td>{row.name}</td>
                    <td>{row.SP_code}</td>
                    {packageTitles?.map((title, index) => (
                      <td
                        key={index}
                        style={{
                          backgroundColor: row[title] === "Yes" ? "" : "#f00",
                          color: row[title] === "Yes" ? "#000" : "#fff",
                        }}
                      >
                        {row[title]}
                      </td>
                    ))}
                    <td>{row.remark}</td>
                  </tr>
                ))}
              />
              {packageExportTotal > paginationLimit && (
                <PackageExportCustomPagination
                  page={packageExportCurrentPage}
                  pageCount={packageExportTotal / paginationLimit}
                  setPackageExportCurrentPage={setPackageExportCurrentPage}
                />
              )}
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default PatientList;
