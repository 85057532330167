import { createSlice } from '@reduxjs/toolkit'
import { getOtherTOTownship } from 'store/actions/otherTOTownship';
import { updateOtherTOTownship } from 'store/actions/otherTOTownship';
import { deleteOtherTOTownship } from 'store/actions/otherTOTownship'
import { createOtherTOTownship } from 'store/actions/otherTOTownship'
import { getOtherTOTownships } from 'store/actions/otherTOTownship'

const initialState = {
  loading: false,
  success: false,
  otherTOTownships: [],
  otherTOTownship: {},
};

const otherTOTownshipSlice = createSlice({
  name: 'otherTOTownship',
  initialState,
  extraReducers: (builder) => {
    // Get otherTOTownships
    builder.addCase(getOtherTOTownships.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOtherTOTownships.fulfilled, (state, action) => {
      state.loading = false;
      state.otherTOTownships = action.payload;
    });
    builder.addCase(getOtherTOTownships.rejected, (state) => {
      state.loading = false;
    });

    // Get otherTOTownship
    builder.addCase(getOtherTOTownship.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOtherTOTownship.fulfilled, (state, action) => {
      state.loading = false;
      state.otherTOTownship = action.payload;
    });
    builder.addCase(getOtherTOTownship.rejected, (state) => {
      state.loading = false;
    });

    // Create otherTOTownship
    builder.addCase(createOtherTOTownship.pending, (state) => {
      state.success = false;
    });
    builder.addCase(createOtherTOTownship.fulfilled, (state, action) => {
      state.success = true;
      state.otherTOTownships = [action.payload, ...state.otherTOTownships];
    });
    builder.addCase(createOtherTOTownship.rejected, (state) => {
      state.success = false;
    });

    // Update otherTOTownship
    builder.addCase(updateOtherTOTownship.pending, (state) => {
      state.success = false;
    });
    builder.addCase(updateOtherTOTownship.fulfilled, (state, action) => {
      let index = state.otherTOTownships.findIndex(
        (otherTOTownship) => otherTOTownship.id === action.payload.id
      );
      state.otherTOTownships[index] = action.payload;
      state.success = true;
      state.otherTOTownship = action.payload;
    });
    builder.addCase(updateOtherTOTownship.rejected, (state) => {
      state.success = false;
    });

    // Delete otherTOTownship
    builder.addCase(deleteOtherTOTownship.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOtherTOTownship.fulfilled, (state, action) => {
      state.loading = false;
      state.otherTOTownships = state.otherTOTownships.filter(
        (otherTOTownship) => otherTOTownship.id !== action.payload
      );
    });
    builder.addCase(deleteOtherTOTownship.rejected, (state) => {
      state.loading = false;
    });
  },
})

export default otherTOTownshipSlice.reducer
