import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import {
  getPfaPatients,
  createPfaPatient,
  updatePfaPatient,
  getPfaPatient,
} from "store/actions/patient";
import BackBtn from "utils/backBtn";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import CustomTable from "components/Table/Table";
import { temDeletePfaPatient } from "store/actions/patient";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { DELETE } from "utils/middleware";

const PatientPsaList = () => {
  const [pfaModal, setPfaModal] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    question_one: null,
    question_two: null,
    question_three: null,
    question_four: null,
    date: "",
    patient_id: "",
    remark: "",
  });
  const { id } = useParams();

  const { pfaPatients, total } = useSelector((state) => state.patient);
  const loading = useSelector((state) => state.patient.loading);
  const dispatch = useDispatch();
  const router = useHistory();
  const total_count = Object.values(formData)
    .slice(1, 5)
    .reduce((acc, val) => acc + Number(val), 0);
  const user = useSelector((state) => state.auth);

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    dispatch(getPfaPatients());
  }, [dispatch]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPfaPatients(query));
  }, [dispatch, router.location.search]);

  const pfaToggle = () => {
    setPfaModal(!pfaModal);
    setFormData({
      id: null,
      question_one: null,
      question_two: null,
      question_three: null,
      question_four: null,
      date: "",
      patient_id: "",
      remark: "",
    });
    if (isUpdate === true) {
      setIsUpdate(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result =
      (total_count <= 2 && "1") ||
      (total_count >= 3 && total_count <= 5 && "2") ||
      (total_count >= 6 && total_count <= 8 && "3") ||
      (total_count >= 9 && total_count <= 12 && "4");
    const payload = {
      ...formData,
      patient_id: id,
      total_count: String(total_count),
      result,
    };
    if (formData.id) {
      await dispatch(updatePfaPatient(payload));
    } else {
      await dispatch(createPfaPatient(payload));
    }
    setPfaModal(false);
  };

  const openDetailModal = async (patientId) => {
    const patientData = await dispatch(getPfaPatient(patientId)).unwrap();
    setFormData({
      id: patientData.id, // Include the id in the formData
      question_one: patientData.question_one,
      question_two: patientData.question_two,
      question_three: patientData.question_three,
      question_four: patientData.question_four,
      date: patientData.date,
      patient_id: patientData.patient_id,
      remark: patientData.remark,
    });
    setPfaModal(true);
  };

  const handleDelete = async (patientId) => {
    if (window.confirm("Are you sure you want to delete this patient?")) {
      await dispatch(temDeletePfaPatient(patientId));
    }
  };

  const closeBtn = (
    <button className="close" onClick={pfaToggle} type="button">
      &times;
    </button>
  );

  const isPGT_FO = useMemo(() => {
    const PGT_id = 6;
    return user?.user?.project?.townships?.find(
      (township) => township?.id === PGT_id
    );
  }, [user?.user?.project?.townships]);

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="mb-0">Patient PFA List</h3>
              {(patientMiddleware(user?.user?.role, CREATE) || isPGT_FO) && (
                <Button size="sm" color="success" onClick={pfaToggle}>
                  New
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <>
              <CustomTable
                header={
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Question One</th>
                    <th scope="col">Question Two</th>
                    <th scope="col">Question Three</th>
                    <th scope="col">Question Four</th>
                    <th scope="col">Patient ID</th>
                    <th scope="col">Total Count</th>
                    <th scope="col">Result</th>
                    <th scope="col">Date</th>
                    <th scope="col">Remark</th>
                    <th scope="col">Action</th>
                  </tr>
                }
                body={pfaPatients
                  ?.filter(
                    (patient) => String(patient.patient_id) === String(id)
                  )
                  ?.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}</td>
                      <td>{row.question_one}</td>
                      <td>{row.question_two}</td>
                      <td>{row.question_three}</td>
                      <td>{row.question_four}</td>
                      <td>{row.patient_id}</td>
                      <td>{row.total_count}</td>
                      <td>{row.result}</td>
                      <td>{row.date}</td>
                      <td>{row.remark}</td>
                      <td>
                        <Button
                          size="sm"
                          color="success"
                          onClick={() => {
                            openDetailModal(row.id);
                            setIsUpdate(true);
                          }}
                        >
                          Detail
                        </Button>
                        {patientMiddleware(user?.user?.role, DELETE) && (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => handleDelete(row.id)}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              />
              {total > paginationLimit && (
                <CustomPagination pageCount={total / paginationLimit} />
              )}
            </>
          </CardBody>
        </Card>
      </Container>

      <Modal
        isOpen={pfaModal}
        toggle={pfaToggle}
        size="lg"
        style={{ maxWidth: "800px", width: "100%" }}
      >
        <ModalHeader toggle={pfaToggle} close={closeBtn}>
          စိတ်ဖိစီးမှုခန့်မှန်းတိုင်းတာခြင်း(PHQ-4)
        </ModalHeader>
        <ModalBody style={{ width: "700px" }}>
          <form onSubmit={handleSubmit}>
            <FormGroup style={{ width: "200px" }}>
              <Label for="date">Date</Label>
              <Input
                id="date"
                name="date"
                placeholder="date placeholder"
                type="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </FormGroup>
            <Table bordered>
              <thead>
                <tr>
                  <th></th>
                  <th>0. လုံးဝမဖြစ်</th>
                  <th>1. အချို့သောရက်များ</th>
                  <th>2. ရက်အားလုံးဧ။် တဝက်ခန့်</th>
                  <th>3. နေ့တိုင်းလိုလို</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Q.1 စိတ်လှုပ်ရှားခြင်း၊
                    <br /> စိုးရိမ်ပူပန်ခြင်း၊ (သို့)
                    <br /> ဂနာ မငြိမ်ဖြစ်ခြင်း
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_one"
                      value="0"
                      checked={formData.question_one === "0"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_one"
                      value="1"
                      checked={formData.question_one === "1"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_one"
                      value="2"
                      checked={formData.question_one === "2"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_one"
                      value="3"
                      checked={formData.question_one === "3"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Q.2 စိတ်ပူပန်ခြင်းကို
                    <br /> ထိန်းချုပ်မရခြင်း (သို့)
                    <br />
                    မရပ်တန့်နိုင်ခြင်း
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_two"
                      value="0"
                      checked={formData.question_two === "0"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_two"
                      value="1"
                      checked={formData.question_two === "1"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_two"
                      value="2"
                      checked={formData.question_two === "2"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_two"
                      value="3"
                      checked={formData.question_two === "3"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Q.3 စိတ်အားငယ်ခြင်း၊
                    <br /> စိတ်ဓာတ်ကျခြင်း (သို့) <br />
                    မျှော်လင့်ချက်ကင်းမဲ့သည်ဟု ခံစားရခြင်း။
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_three"
                      value="0"
                      checked={formData.question_three === "0"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_three"
                      value="1"
                      checked={formData.question_three === "1"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_three"
                      value="2"
                      checked={formData.question_three === "2"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_three"
                      value="3"
                      checked={formData.question_three === "3"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Q.4 အလုပ်များ လုပ်ကိုင်ရာတွင်
                    <br /> စိတ်ဝင်စားမှု (သို့) <br />
                    ပျော်ရွှင်မှု အနည်းငယ်သာရှိခြင်း။
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_four"
                      value="0"
                      checked={formData.question_four === "0"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_four"
                      value="1"
                      checked={formData.question_four === "1"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_four"
                      value="2"
                      checked={formData.question_four === "2"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      type="radio"
                      name="question_four"
                      value="3"
                      checked={formData.question_four === "3"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table bordered>
              <thead>
                <tr>
                  <th>စုစုပေါင်းရမှတ်</th>
                  <th style={{ textAlign: "center", fontSize: "16px" }}>
                    {Object.values(formData)
                      .slice(1, 5) // Skip the id field
                      .reduce((acc, val) => acc + Number(val), 0)}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    backgroundColor: total_count <= 2 && "green",
                    color: total_count <= 2 && "white",
                  }}
                >
                  <td>0 - 2 ကောင်းမွန်သည့်အခြေအနေ</td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    backgroundColor:
                      total_count >= 3 && total_count <= 5 && "green",
                    color: total_count >= 3 && total_count <= 5 && "white",
                  }}
                >
                  <td>3 - 5 အနည်းငယ်ဖိစီးသည့်အခြေအနေ</td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    backgroundColor:
                      total_count >= 6 && total_count <= 8 && "green",
                    color: total_count >= 6 && total_count <= 8 && "white",
                  }}
                >
                  <td>6 - 8 အတော်အသင့်ဖိစီးသည့်အခြေအနေ</td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    backgroundColor:
                      total_count >= 9 && total_count <= 12 && "green",
                    color: total_count >= 9 && total_count <= 12 && "white",
                  }}
                >
                  <td>9 - 12 ပြင်းထန်စွာဖိစီးသည့်အခြေအနေ</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>

            <FormGroup style={{ width: "280px" }}>
              <Label for="date">Remark</Label>
              <Input
                id="remark"
                name="remark"
                placeholder="Remark"
                value={formData.remark}
                onChange={handleInputChange}
                required={false}
              />
            </FormGroup>
            <ModalFooter>
              {(patientMiddleware(user?.user?.role, CREATE) ||
                isPGT_FO ||
                isUpdate === false) && (
                <Button color="primary" type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              )}

              <Button color="secondary" onClick={pfaToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PatientPsaList;
