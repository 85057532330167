import { createSlice } from '@reduxjs/toolkit'
import {
  createRegion,
  getRegions,
  getRegion,
  updateRegion,
  deleteRegion,
} from 'store/actions/region'

const initialState = {
  loading: false,
  success: false,
  regions: [],
  region: {},
}

const regionSlice = createSlice({
  name: 'region',
  initialState,
  extraReducers: (builder) => {
    // Get Regions
    builder.addCase(getRegions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.loading = false
      state.regions = action.payload
    })
    builder.addCase(getRegions.rejected, (state) => {
      state.loading = false
    })

    // Create Regions
    builder.addCase(createRegion.pending, (state) => {
      state.success = false
    })
    builder.addCase(createRegion.fulfilled, (state, action) => {
      state.success = true
      state.regions = [action.payload, ...state.regions]
    })
    builder.addCase(createRegion.rejected, (state) => {
      state.success = false
    })

    // Get Region
    builder.addCase(getRegion.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getRegion.fulfilled, (state, action) => {
      state.loading = false
      state.region = action.payload
    })
    builder.addCase(getRegion.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update Region
    builder.addCase(updateRegion.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateRegion.fulfilled, (state, action) => {
      let index = state.regions.findIndex(
        (region) => region.id === action.payload.id,
      )
      state.regions[index] = action.payload
      state.success = true
      state.region = action.payload
    })
    builder.addCase(updateRegion.rejected, (state) => {
      state.success = false
    })

    // Delete Region
    builder.addCase(deleteRegion.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteRegion.fulfilled, (state, action) => {
      state.loading = false
      state.regions = state.regions.filter(
        (region) => region.id !== action.payload,
      )
    })
    builder.addCase(deleteRegion.rejected, (state) => {
      state.loading = false
    })
  },
})

export default regionSlice.reducer
