import axios from 'axios'
import { removeToken } from 'utils/cache'
import { setToken } from 'utils/cache'

  // export const host = 'http://localhost:8001/api/v1'
export const host = 'https://mdrsps2-api.unionais.org/api/v1'
// export const host = "http://127.0.0.1:8000/api/v1";

export const setAccessToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    setToken(token)
  } else {
    delete axios.defaults.headers.common['Authorization']
    removeToken()
  }
}

export const call = async (method, path, data) => {
  const response = await axios[method](`${host}/${path}`, data)
  return response.data
}
