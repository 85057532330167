import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomModal from "components/Modal/CustomModal";
import { roles } from "variables/options";
import { useSelector, useDispatch } from "react-redux";
import { createAccount } from "store/actions/account";
import { getTownships } from "store/actions/township";

const AccountCreate = ({ isOpen, toggle }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const { account, township, project } = useSelector((state) => state);
  const { success } = account;
  const dispatch = useDispatch();

  const [role, setRole] = useState(null);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const projects = project.projects?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createAccount({
        name: data.name,
        email: data.email,
        role: data.role.value,
        township_ids: selectedTownship?.map((ele) => ele.value),
        project_id: selectedProject?.value,
      })
    );
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setRole(null);
    setSelectedTownship(null);
    setSelectedProject(null);
    reset();
  }, [reset, setRole, setSelectedTownship, setSelectedProject]);

  useEffect(() => {
    if (success) {
      formReset();
    }
  }, [success, formReset]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Account"
      onClick={() => {
        toggle(false);
        formReset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
          <CustomInput
            id="email"
            label="Email"
            register={{
              ...register("email", {
                required: "Email is required!",
              }),
            }}
            placeholder="Enter email"
            type="email"
            errors={errors}
          />
          <CustomSelect
            id="role"
            label="Select Role"
            rules={{ required: "Role is required!" }}
            control={control}
            options={roles}
            value={role}
            setData={(data) => {
              setSelectedTownship(null);
              setSelectedProject(null);
              setRole(data);
            }}
            placeholder="Select role"
          />
          {role?.value === "Field Officer" && (
            <>
              <CustomSelect
                id="project"
                label="Select Project"
                rules={{
                  required:
                    role?.value === "Field Officer"
                      ? "Project is required!"
                      : false,
                }}
                control={control}
                options={projects}
                value={selectedProject}
                setData={(data) => {
                  setSelectedProject(data);
                  dispatch(getTownships({ project_id: data?.value }));
                }}
                placeholder="Select project"
              />
              <CustomSelect
                id="township"
                label="Select Township"
                rules={{
                  required:
                    role?.value === "Field Officer"
                      ? "Township is required!"
                      : false,
                }}
                control={control}
                options={townships}
                value={selectedTownship}
                setData={setSelectedTownship}
                placeholder="Select township"
                isMulti={true}
              />
            </>
          )}
          {role?.value === "Programme Manager_MDR" && (
            <CustomSelect
              id="project"
              label="Select Project"
              rules={{
                required:
                  role?.value === "Programme Manager_MDR"
                    ? "Project is required!"
                    : false,
              }}
              control={control}
              options={projects}
              value={selectedProject}
              setData={setSelectedProject}
              placeholder="Select project"
            />
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default AccountCreate;
