import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from 'store/actions/auth'

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { loading } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    dispatch(changePassword(data))
  }

  return (
    <Container className="mt-3" fluid>
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-3">Change Password</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="current_password"
                    type="password"
                    label="Current Password"
                    register={{
                      ...register('current_password', {
                        required: 'Current Password is required!',
                      }),
                    }}
                    placeholder="Enter Current Password"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="new_password"
                    type="password"
                    label="New Password"
                    register={{
                      ...register('new_password', {
                        required: 'New Password is required!',
                      }),
                    }}
                    placeholder="Enter New Password"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="new_password_confirmation"
                    type="password"
                    label="New Password Confirmation"
                    register={{
                      ...register('new_password_confirmation', {
                        required: 'New Password Confirmation is required!',
                      }),
                    }}
                    placeholder="Enter New Password Confirmation"
                    errors={errors}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ChangePassword
