import { createSlice } from '@reduxjs/toolkit'
import { getCPIReport } from 'store/actions/report'
import {
  getReport,
} from 'store/actions/report'

const initialState = {
  loading: false,
  success: false,
  report: {},
  CPIReport: [],
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  extraReducers: (builder) => {
    
    // Get Report
    builder.addCase(getReport.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false
      state.report = action.payload
    })
    builder.addCase(getReport.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Get CPI Report
    builder.addCase(getCPIReport.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getCPIReport.fulfilled, (state, action) => {
      state.loading = false
      state.CPIReport = action.payload
    })
    builder.addCase(getCPIReport.rejected, (state) => {
      state.loading = false
      state.success = false
    })
  },
})

export default reportSlice.reducer
