import { createSlice } from '@reduxjs/toolkit'
import {
  createTownship,
  getTownships,
  getTownship,
  updateTownship,
  deleteTownship,
} from 'store/actions/township'

const initialState = {
  loading: false,
  success: false,
  townships: [],
  township: {},
  total: 0,
}

const townshipSlice = createSlice({
  name: 'township',
  initialState,
  extraReducers: (builder) => {
    // Get townships
    builder.addCase(getTownships.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTownships.fulfilled, (state, action) => {
      state.loading = false
      state.townships = action.payload.data
      state.total = action.payload.total
    })
    builder.addCase(getTownships.rejected, (state) => {
      state.loading = false
    })

    // Create townships
    builder.addCase(createTownship.pending, (state) => {
      state.success = false
    })
    builder.addCase(createTownship.fulfilled, (state, action) => {
      state.success = true
      state.townships = [action.payload, ...state.townships]
      state.total = state.total + 1
    })
    builder.addCase(createTownship.rejected, (state) => {
      state.success = false
    })

    // Get township
    builder.addCase(getTownship.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getTownship.fulfilled, (state, action) => {
      state.loading = false
      state.township = action.payload
    })
    builder.addCase(getTownship.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update township
    builder.addCase(updateTownship.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateTownship.fulfilled, (state, action) => {
      let index = state.townships.findIndex(
        (township) => township.id === action.payload.id,
      )
      state.townships[index] = action.payload
      state.success = true
      state.township = action.payload
    })
    builder.addCase(updateTownship.rejected, (state) => {
      state.success = false
    })

    // Delete township
    builder.addCase(deleteTownship.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteTownship.fulfilled, (state, action) => {
      state.loading = false
      state.townships = state.townships.filter(
        (township) => township.id !== action.payload,
      )
      state.total = state.total - 1
    })
    builder.addCase(deleteTownship.rejected, (state) => {
      state.loading = false
    })
  },
})

export default townshipSlice.reducer
