import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory, useParams } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatients } from "store/actions/patient";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { patientMiddleware, EXPORT, CREATE } from "utils/middleware";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { getTownship } from "store/actions/township";
import { patientExport } from "utils/patientExport";
import { call } from "services/api";
import { currentDate } from "utils/currentDate";

const TOPatientFOList = () => {
  const router = useHistory();
  const { townshipId } = useParams();

  const [exportLoading, setExportLoading] = useState(false);
  const [pfaPatientExportLoading, setPfaPatientExportLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allPackageExportLoading, setAllPackageExportLoading] = useState(false);

  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  const { patients, male, female, total } = useSelector(
    (state) => state.patient
  );
  const loading = useSelector((state) => state.patient.loading);
  const user = useSelector((state) => state.auth);
  const township = useSelector((state) => state.township.township);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownship(townshipId));
  }, [townshipId, dispatch]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.TO_status = "Yes";
    if (!("page" in query)) {
      query.page = 1;
    }
    query.current_township_id = townshipId;
    dispatch(getPatients(query));
  }, [dispatch, router.location.search, townshipId]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.current_township_id = townshipId;

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.SP_code = code;
    } else {
      delete query["SP_code"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.current_township_id = townshipId;
      const { response, result } = await patientExport(query);

      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      let fileName = "TI-Patients";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleRefresh = () => {
    router.replace(router.location.pathname);
  };

  const handlePfaPatientExport = async () => {
    setPfaPatientExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);

      const response = await call(
        "get",
        `pfa-patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      let fileName = "Pfa-Patient";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setPfaPatientExportLoading(false);
  };

  const handleAllPackageExport = async () => {
    setAllPackageExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);

      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      const response = await call(
        "get",
        `all-support-packages-export?${new URLSearchParams(query).toString()}`
      );

      const result = response.data;

      let fileName = "All-Package";
      if (township) {
        fileName += `-${township?.name}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Please try again!");
    }
    setAllPackageExportLoading(false);
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">TI/Patients ({township.name})</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button size="sm" color="primary" onClick={handleRefresh}>
                  <i class="fas fa-redo"></i>
                </Button>
                {patientMiddleware(user?.user?.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push(`/admin/create-patient`)}
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search SP Code..."
                />
              </Col>
              <Col sm={2}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
              <Col sm={3}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={6}>
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleAllPackageExport}
                    disabled={allPackageExportLoading}
                    className="mt-5"
                  >
                    {allPackageExportLoading ? "Loading" : "All Package Export"}
                  </Button>
                )}

                <Button
                  size="sm"
                  color="info"
                  onClick={handlePfaPatientExport}
                  disabled={pfaPatientExportLoading}
                  className="mt-5"
                >
                  {pfaPatientExportLoading ? "Loading" : "PfaPatient Export"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Male = {male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Female = {female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Total = {total}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">SP Code</th>
                <th scope="col">PFA</th>
                <th scope="col">Action</th>
                <th scope="col">TSP</th>
                <th scope="col">RR Code</th>
                <th scope="col">DRTB Code</th>
                <th scope="col">Township DRTB Code</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Sex</th>
                <th scope="col">TO Township DRTB Code</th>
              </tr>
            }
            body={patients?.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.SP_code}</td>
                {row.township?.abbreviation === "PGT" ||
                row.township?.abbreviation === "TGI" ? (
                  <td>
                    <Button
                      size="sm"
                      color="info"
                      onClick={() =>
                        router.push(`/admin/patient-pfa-list/${row.id}`)
                      }
                    >
                      PFA
                    </Button>
                  </td>
                ) : (
                  <td></td>
                )}
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/patient-detail/${row.id}`)
                    }
                  >
                    Detail
                  </Button>
                </td>
                <td>{row.township?.abbreviation}</td>
                <td>{row.RR_code}</td>
                <td>{row.DRTB_code}</td>
                <td>{row.unique_id}</td>
                <td>{row.name}</td>
                <td>{row.age}</td>
                <td>{row.sex}</td>
                <td>{row.TO_unique_id}</td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default TOPatientFOList;
