import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import { deletePfaPatient } from "store/actions/patient";
import { recoverPatient } from "store/actions/patient";
import { getPfaPatients } from "store/actions/patient";
import { recoverPfaPatient } from "store/actions/patient";

const TrashPatientPsaList = () => {
  const router = useHistory();

  const [alert, setAlert] = useState(null);

  const { pfaPatients, total } = useSelector((state) => state.patient);
  const loading = useSelector((state) => state.patient.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.status = 0;
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPfaPatients(query));
  }, [dispatch, router.location.search]);
  const user = useSelector((state) => state.auth);

  const isAuthorizeForPfa =
    user?.user?.email === "phonesuukhaing@theunion.org" ||
    user?.user?.email === "nay.mon@theunion.org" ||
    user?.user?.email === "myanandar.aung@theunion.org";

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deletePfaPatient(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const handleRecover = async (id) => {
    await dispatch(recoverPfaPatient(id));
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Trash PFA Patients</h3>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Question One</th>
                <th scope="col">Question Two</th>
                <th scope="col">Question Three</th>
                <th scope="col">Question Four</th>
                <th scope="col">Patient ID</th>
                <th scope="col">Total Count</th>
                <th scope="col">Result</th>
                <th scope="col">Date</th>
                <th scope="col">Remark</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={pfaPatients?.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.question_one}</td>
                <td>{row.question_two}</td>
                <td>{row.question_three}</td>
                <td>{row.question_four}</td>
                <td>{row.patient_id}</td>
                <td>{row.total_count}</td>
                <td>{row.result}</td>
                <td>{row.date}</td>
                <td>{row.remark}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      handleRecover(row.id);
                    }}
                  >
                    Recover
                  </Button>
                  {isAuthorizeForPfa && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default TrashPatientPsaList;
