import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccountEmail } from 'store/actions/account'

const AccountEmailUpdate = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const { account, success } = useSelector((state) => state.account)
  const dispatch = useDispatch()

  useEffect(() => {
    const { email } = account

    setValue('email', email)
  }, [setValue, account])

  const onSubmit = async (data) => {
    setLoading(true)
    await dispatch(
      updateAccountEmail({
        id: account.id,
        email: data.email,
      }),
    )
    setLoading(false)
  }

  useEffect(() => {
    if (success) {
      toggle(false)
    }
  }, [success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Account Email"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="email"
            label="Email"
            register={{
              ...register('email', {
                required: 'Email is required!',
              }),
            }}
            placeholder="Enter email"
            type="email"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

export default AccountEmailUpdate
