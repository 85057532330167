export const roles = [
  {
    value: "Senior Technical Adviser (TB)",
    label: "Senior Technical Adviser (TB)",
  },
  { value: "Deputy Programme Director", label: "Deputy Programme Director" },
  { value: "Programme Director", label: "Programme Director" },

  { value: "Programme Manager_MDR", label: "Programme Manager_MDR" },
  { value: "Programme Manager_AIS", label: "Programme Manager_AIS" },
  {
    value: "MEAL and Digital Tool Manager",
    label: "MEAL and Digital Tool Manager",
  },
  {
    value: "MEAL And Digital Tool Officer",
    label: "MEAL And Digital Tool Officer",
  },
  { value: "MEAL Associate", label: "MEAL Associate" },
  { value: "Project Officer", label: "Project Officer" },
  { value: "Medical Officer", label: "Medical Officer" },
  { value: "Finance Department", label: "Finance Department" },
  { value: "Field Officer", label: "Field Officer" },
  { value: "Data Assistant", label: "Data Assistant" },
];

export const sex = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const years = [
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

export const reportMonths = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
  // { value: '1 Quarter', label: '1st Quarter' },
  // { value: '2 Quarter', label: '2nd Quarter' },
  // { value: '3 Quarter', label: '3rd Quarter' },
  // { value: '4 Quarter', label: '4th Quarter' },
  // { value: 'Annual', label: 'Annual' },
];

export const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const outcomes = [
  { value: "Cured", label: "Cured" },
  { value: "Completed", label: "Completed" },
  { value: "Died", label: "Died" },
  { value: "Lost to Follow up", label: "Lost to Follow up" },
  { value: "Failed", label: "Failed" },
  { value: "Move to XDR", label: "Move to XDR" },
  { value: "Not Evaluated", label: "Not Evaluated" },
];

export const packageNo = [
  { value: 1, label: "Package 1" },
  { value: 2, label: "Package 2" },
  { value: 3, label: "Package 3" },
  { value: 4, label: "Package 4" },
  { value: 5, label: "Package 5" },
  { value: 6, label: "Package 6" },
  { value: 7, label: "Package 7" },
  { value: 8, label: "Package 8" },
  { value: 9, label: "Package 9" },
];

export const treatmentRegimens = [
  { value: "1. LTR", label: "1. LTR" },
  { value: "2. OSSTR", label: "2. OSSTR" },
  { value: "3. OLTR", label: "3. OLTR" },
  { value: "4. BPal", label: "4. BPal" },
  { value: "5. BPalM", label: "5. BPalM" },
  { value: "6. Individualized MDR", label: "6. Individualized MDR" },
  { value: "7. Individualized Pre-XDR", label: "7. Individualized Pre-XDR" },
  { value: "8. XDR", label: "8. XDR" },
  { value: "9. Other", label: "9. Other" },
];

export const supportMonths = [
  { value: -1, label: "Pre-enroll Month" },
  { value: 0, label: "Month 0" },
  { value: 1, label: "Month 1" },
  { value: 2, label: "Month 2" },
  { value: 3, label: "Month 3" },
  { value: 4, label: "Month 4" },
  { value: 5, label: "Month 5" },
  { value: 6, label: "Month 6" },
  { value: 7, label: "Month 7" },
  { value: 8, label: "Month 8" },
  { value: 9, label: "Month 9" },
  { value: 10, label: "Month 10" },
  { value: 11, label: "Month 11" },
  { value: 12, label: "Month 12" },
  { value: 13, label: "Month 13" },
  { value: 14, label: "Month 14" },
  { value: 15, label: "Month 15" },
  { value: 16, label: "Month 16" },
  { value: 17, label: "Month 17" },
  { value: 18, label: "Month 18" },
  { value: 19, label: "Month 19" },
  { value: 20, label: "Month 20" },
  { value: 21, label: "Month 21" },
  { value: 22, label: "Month 22" },
  { value: 23, label: "Month 23" },
  { value: 24, label: "Month 24" },
];

export const reportedPeriods = [
  { value: "FY22Q3Q4", label: "FY22Q3Q4" },
  { value: "FY23Q1Q2", label: "FY23Q1Q2" },
  { value: "FY23Q3Q4", label: "FY23Q3Q4" },
  { value: "FY24Q1Q2", label: "FY24Q1Q2" },
  { value: "FY24Q3Q4", label: "FY24Q3Q4" },
  { value: "FY25Q1Q2", label: "FY25Q1Q2" },
  { value: "FY25Q3Q4", label: "FY25Q3Q4" },
  { value: "FY26Q1Q2", label: "FY26Q1Q2" },
  { value: "FY26Q3Q4", label: "FY26Q3Q4" },
];
