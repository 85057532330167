import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getTownships } from "store/actions/township";
import BackBtn from "utils/backBtn";
import { useHistory } from "react-router";
import { patientMiddleware, EXPORT } from "utils/middleware";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { patientExport } from "utils/patientExport";

const FilterTownshipList = () => {
  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const { townships } = useSelector((state) => state.township);
  const user = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  const handleNavigate = (townshipId) => {
    if (router.location.pathname === "/admin/patient-township-list") {
      router.push(`/admin/patient-FO-list/${townshipId}`);
    } else {
      router.push(`/admin/TO-patient-FO-list/${townshipId}`);
    }
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const { response, result } = await patientExport(query);

      let fileName = "All-Patients";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3">
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">
                  Townships (
                  {router.location.pathname === "/admin/patient-township-list"
                    ? "Patients"
                    : "TI/Patients"}
                  )
                </h3>
              </Col>
              <Col xs="6" className="text-right">
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Patient Export"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {townships?.map((township) => (
                <Col className="mt-3" sm={3} key={township.id}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => handleNavigate(township.id)}
                  >
                    {township.name}
                  </Button>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default FilterTownshipList;
