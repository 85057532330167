export const calculateAge = (inputDate) => {
  // Split the input date into year, month, and day components
  const parts = inputDate.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1; // Month is 0-based
  const day = parseInt(parts[2]);

  // Create a Date object for the input date
  const inputDateObj = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age
  let age = currentDate.getFullYear() - inputDateObj.getFullYear();

  // Check if the birthday has occurred this year
  if (
    currentDate.getMonth() < inputDateObj.getMonth() ||
    (currentDate.getMonth() === inputDateObj.getMonth() &&
      currentDate.getDate() < inputDateObj.getDate())
  ) {
    age--;
  }

  return age;
};
