import Login from "views/pages/auth/Login";
import Dashboard from "views/pages/dashboard";
import AccountList from "views/pages/dashboard/account/AccountList";
import DistrictList from "views/pages/dashboard/basicData/district/DistrictList";
import RegionList from "views/pages/dashboard/basicData/region/RegionList";
import TownshipList from "views/pages/dashboard/basicData/township/TownshipList";
import ChangePassword from "views/pages/auth/ChangePassword";
import PatientCreate from "views/pages/dashboard/patient/PatientCreate";
import ProjectList from "views/pages/dashboard/basicData/project/ProjectList";
import PatientEdit from "views/pages/dashboard/patient/PatientEdit";
import TrashPatientList from "views/pages/dashboard/trash/TrashPatientList";
import PatientOutcomeEdit from "views/pages/dashboard/patient/PatientOutcomeEdit";
import FilterTownshipList from "views/pages/dashboard/patient/FilterTownshipList";
import PatientFOList from "views/pages/dashboard/patient/PatientFOList";
import TOPatientFOList from "views/pages/dashboard/patient/TOPatientFOList";
import PatientList from "views/pages/dashboard/patient/PatientList";
import TOPatientList from "views/pages/dashboard/patient/TOPatientList";
import PatientDetail from "views/pages/dashboard/patient/PatientDetail";
import SupportPackageCreate from "views/pages/dashboard/patient/supportPackages/SupportPackageCreate";
import PackageList from "views/pages/dashboard/basicData/package/PackageList";
import SupportPackageEdit from "views/pages/dashboard/patient/supportPackages/SupportPackageEdit";
import Report from "views/pages/dashboard/report/Report";
import CPIReportList from "views/pages/dashboard/report/CPIReport";
import TrashSupportPackageList from "views/pages/dashboard/trash/TrashSupportPackageList";
import PatientReportEdit from "views/pages/dashboard/patient/PatientReportEdit";
import PatientPsaList from "views/pages/dashboard/patient/PatientPsaList";
import TrashPatientPsaList from "views/pages/dashboard/trash/TrashPatientPsaList";

const routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-archive-2 text-green",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    icon: "ni ni-archive-2 text-green",
    component: ChangePassword,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/account-list",
    name: "Accounts",
    icon: "ni ni-single-02 text-primary",
    component: AccountList,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Basic Data",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "basicDataCollapse",
    views: [
      {
        path: "/region-list",
        name: "State/Regions",
        miniName: "RL",
        component: RegionList,
        layout: "/admin",
      },
      {
        path: "/district-list",
        name: "Districts",
        miniName: "DL",
        component: DistrictList,
        layout: "/admin",
      },
      {
        path: "/project-list",
        name: "Projects",
        miniName: "PL",
        component: ProjectList,
        layout: "/admin",
      },
      {
        path: "/township-list",
        name: "Townships",
        miniName: "TL",
        component: TownshipList,
        layout: "/admin",
      },
      {
        path: "/package-list",
        name: "Packages",
        miniName: "PL",
        component: PackageList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Patients",
    icon: "ni ni-collection text-primary",
    state: "patientCollapse",
    views: [
      {
        path: "/patient-list",
        name: "All Patients",
        miniName: "AP",
        component: PatientList,
        layout: "/admin",
      },
      {
        path: "/TO-patient-list",
        name: "TI/Patients",
        miniName: "TI/P",
        component: TOPatientList,
        layout: "/admin",
      },
      {
        path: "/patient-township-list",
        name: "All Patients(FO)",
        miniName: "AP(FO)",
        component: FilterTownshipList,
        layout: "/admin",
      },
      {
        path: "/TO-patient-township-list",
        name: "TI/Patients(FO)",
        miniName: "TI/P(FO)",
        component: FilterTownshipList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/patient-FO-list/:townshipId",
    name: "All Patients(FO)",
    component: PatientFOList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/TO-patient-FO-list/:townshipId",
    name: "TO/Patients(FO)",
    component: TOPatientFOList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/create-patient",
    name: "Create Patient",
    icon: "ni ni-collection text-primary",
    component: PatientCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-detail/:id",
    name: "Patient Detail",
    icon: "ni ni-collection text-primary",
    component: PatientDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-edit/:id",
    name: "Patient Edit",
    icon: "ni ni-collection text-primary",
    component: PatientEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-outcome-edit/:id",
    name: "Patient Outcome Edit",
    icon: "ni ni-collection text-primary",
    component: PatientOutcomeEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-report-outcome-edit/:id",
    name: "Patient Report Outcome Edit",
    icon: "ni ni-collection text-primary",
    component: PatientReportEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-pfa-list/:id",
    name: "Patient PSA List",
    icon: "ni ni-collection text-primary",
    component: PatientPsaList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/package-create/:id",
    name: "Package Create",
    icon: "ni ni-collection text-primary",
    component: SupportPackageCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/package-edit/:id/:supportPackageId",
    name: "Package Edit",
    icon: "ni ni-collection text-primary",
    component: SupportPackageEdit,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Report",
    icon: "ni ni-chart-pie-35 text-primary",
    state: "reportCollapse",
    views: [
      {
        path: "/report",
        name: "SPS Report",
        miniName: "SPS",
        component: Report,
        layout: "/admin",
      },
      {
        path: "/CPI-report",
        name: "CPI Report",
        miniName: "CPI",
        component: CPIReportList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Trash",
    icon: "ni ni-app text-primary",
    state: "trashCollapse",
    views: [
      {
        path: "/patient-trash-list",
        name: "Patients",
        miniName: "AP",
        component: TrashPatientList,
        layout: "/admin",
      },
      {
        path: "/support-package-trash-list",
        name: "Support Packages",
        miniName: "SP",
        component: TrashSupportPackageList,
        layout: "/admin",
      },
      {
        path: "/pfa-patient-trash-list",
        name: "PFA Patients",
        miniName: "AP",
        component: TrashPatientPsaList,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
