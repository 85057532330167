import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getAccounts = createAsyncThunk(
  'account/getAccounts',
  async (query) => {
    try {
      const result = await call(
        'get',
        `users?${new URLSearchParams(query).toString()}`,
      )

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getAccount = createAsyncThunk('account/getAccount', async (id) => {
  try {
    const response = await call('get', `users/${id}`)
    const result = response.data
    return result
  } catch (error) {
    const { status, data } = error.response

    if (status === 401) {
      setAccessToken(null)
      NotificationManager.error(data.data.message)
    } else {
      NotificationManager.error(serverErrorMessage)
    }
    return error.message
  }
})

export const createAccount = createAsyncThunk(
  'account/createAccount',
  async (data) => {
    try {
      const response = await call('post', 'io-register', data)
      const result = response.data

      NotificationManager.success('Account has been created successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const updateAccount = createAsyncThunk(
  'account/updateAccount',
  async (data) => {
    try {
      const response = await call('post', `users/${data.id}?_method=PUT`, data)
      const result = response.data

      NotificationManager.success('Account has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const updateAccountEmail = createAsyncThunk(
  'account/updateAccountEmail',
  async (data) => {
    try {
      const response = await call(
        'post',
        `email-users/${data.id}?_method=PUT`,
        data,
      )
      const result = response.data

      NotificationManager.success('Email has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const deleteAccount = createAsyncThunk(
  'account/deleteAccount',
  async (id) => {
    try {
      await call('delete', `users/${id}`)

      NotificationManager.success('Account has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)
