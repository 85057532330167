import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import ProjectCreate from "./ProjectCreate";
import ProjectUpdate from "./ProjectUpdate";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { getProjects, getProject, deleteProject } from "store/actions/project";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { basicDataMiddleware, CREATE, UPDATE, DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";

const ProjectList = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [alert, setAlert] = useState(null);

  const { project, auth } = useSelector((state) => state);
  const { loading, projects } = project;
  const { user } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          dispatch(deleteProject(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Projects</h3>
              </Col>

              {basicDataMiddleware(user.role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={projects?.map((project, index) => (
              <tr key={project.id}>
                <td>{index + 1}</td>
                <td>{project.name}</td>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await dispatch(getProject(project.id));
                          setIsUpdateOpen(true);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(project.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <ProjectUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <ProjectCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

export default ProjectList;
