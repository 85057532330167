import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { updateSupportPackageTownship } from "store/actions/supportPackage";

const SupportTownshipUpdateButton = ({ support_package_id, township_id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = async () => {
    if(!township_id) {
        return;
    }
    setLoading(true);

    await dispatch(
      updateSupportPackageTownship({
        id: support_package_id,
        township_id: township_id,
      })
    );

    setLoading(false);
  };

  return (
    <Button color="danger" size="sm" disabled={loading} onClick={onSubmit}>
      {loading ? "Loading" : "Update Township"}
    </Button>
  );
};

export default SupportTownshipUpdateButton;
