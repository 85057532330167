import React from 'react'
import { FormGroup } from 'reactstrap'

const CustomNumberInput = React.forwardRef(
  (
    {
      id,
      label,
      register,
      placeholder,
      errors,
      onChange,
      isRequired = true,
      disabled = false,
      max = '',
    },
    ref,
  ) => {
    return (
      <FormGroup>
        <label htmlFor={id}>
          {label} {isRequired && <sup className="text-danger">*</sup>}
        </label>
        <input
          ref={ref}
          id={id}
          className="form-control"
          {...register}
          placeholder={placeholder}
          type="number"
          step=".01"
          min={0}
          max={max}
          disabled={disabled}
          onChange={onChange}
        />
        {errors[id] && (
          <span className="text-danger">{errors[id]?.message}</span>
        )}
      </FormGroup>
    )
  },
)

export default CustomNumberInput
