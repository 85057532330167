import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { updateDistrict } from 'store/actions/district'

const DistrictUpdate = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const { success, district } = useSelector((state) => state.district)
  const dispatch = useDispatch()

  useEffect(() => {
    const { name } = district

    setValue('name', name)
  }, [setValue, district])

  const onSubmit = async (data) => {
    setLoading(true)
    await dispatch(updateDistrict({ id: district.id, name: data.name }))
    setLoading(false)
  }

  useEffect(() => {
    if (success) {
      toggle(false)
    }
  }, [success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update District"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="District Name"
            register={{
              ...register('name', {
                required: 'District Name is required!',
              }),
            }}
            placeholder="Enter district name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

export default DistrictUpdate
