import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { packageNo } from "variables/options";
import { updatePackage } from "store/actions/package";
import CustomTextArea from "components/Inputs/CustomTextArea";

const PackageUpdate = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const { success, package: p } = useSelector((state) => state.package);
  const dispatch = useDispatch();

  useEffect(() => {
    const { package_no, amount, max_time, description } = p;

    setValue("package_no", {
      value: package_no,
      label: `Package ${package_no}`,
    });
    setSelectedPackage({ value: package_no, label: `Package ${package_no}` });
    setValue("amount", amount);
    setValue("max_time", max_time);
    setValue("description", description)
  }, [setValue, p]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updatePackage({
        id: p.id,
        package_no: selectedPackage.value,
        amount: data.amount,
        max_time: data.max_time,
        description: data.description,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (success) {
      toggle(false);
    }
  }, [success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Package"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="package_no"
            label="Package No"
            rules={{ required: "Package No is required!" }}
            control={control}
            options={packageNo}
            value={selectedPackage}
            setData={setSelectedPackage}
            placeholder="Package No"
          />

          <CustomNumberInput
            id="amount"
            label="Amount"
            register={{
              ...register("amount", {
                required: "Age is required!",
              }),
            }}
            placeholder="Enter amount"
            errors={errors}
          />

          <CustomNumberInput
            id="max_time"
            label="Max Time"
            register={{
              ...register("max_time", {
                required: "Max Time is required!",
              }),
            }}
            placeholder="Enter Max Time"
            errors={errors}
            max={120}
          />

          <CustomTextArea
            id="description"
            label="Description"
            register={{
              ...register("description"),
            }}
            placeholder="Enter Description"
            errors={errors}
            isRequired={false}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default PackageUpdate;
