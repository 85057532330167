import { createSlice } from '@reduxjs/toolkit'
import {
  getAccounts,
  createAccount,
  getAccount,
  updateAccount,
  updateAccountEmail,
  deleteAccount,
} from 'store/actions/account'

const initialState = {
  loading: false,
  success: false,
  accounts: [],
  account: {},
  total: 0,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: (builder) => {
    // Get Accounts
    builder.addCase(getAccounts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.loading = false
      state.accounts = action.payload.data
      state.total = action.payload.total
    })
    builder.addCase(getAccounts.rejected, (state) => {
      state.loading = false
    })

    // Create Account
    builder.addCase(createAccount.pending, (state) => {
      state.success = false
    })
    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.success = true
      state.accounts = [action.payload, ...state.accounts]
      state.total = state.total + 1
    })
    builder.addCase(createAccount.rejected, (state) => {
      state.success = false
    })

    // Get Account
    builder.addCase(getAccount.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.loading = false
      state.account = action.payload
    })
    builder.addCase(getAccount.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update Account
    builder.addCase(updateAccount.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      let index = state.accounts.findIndex(
        (account) => account.id === action.payload.id,
      )
      state.accounts[index] = action.payload
      state.success = true
      state.account = action.payload
    })
    builder.addCase(updateAccount.rejected, (state) => {
      state.success = false
    })

    // Update Email
    builder.addCase(updateAccountEmail.pending, (state) => {
      state.success = false
    })
    builder.addCase(updateAccountEmail.fulfilled, (state, action) => {
      let index = state.accounts.findIndex(
        (account) => account.id === action.payload.id,
      )
      state.accounts[index] = action.payload
      state.success = true
      state.account = action.payload
    })
    builder.addCase(updateAccountEmail.rejected, (state) => {
      state.success = false
    })

    // Delete Account
    builder.addCase(deleteAccount.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.loading = false
      state.accounts = state.accounts.filter(
        (account) => account.id !== action.payload,
      )
      state.total = state.total - 1
    })
    builder.addCase(deleteAccount.rejected, (state) => {
      state.loading = false
    })
  },
})

export default accountSlice.reducer
