import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Container, Card, CardHeader, Row, Col, Button } from "reactstrap";
import BackBtn from "utils/backBtn";
import styles from "./Report.module.css";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useHistory } from "react-router";
import queryString from "query-string";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import classnames from "classnames";
import { getTownships } from "store/actions/township";
import { reportMonths, years } from "variables/options";
import { getReport } from "store/actions/report";
import { currentDate } from "utils/currentDate";

const Report = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const township = useSelector((state) => state.township);
  const report = useSelector((state) => state.report.report);

  const loading = useSelector((state) => state.report.loading);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getReport(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (month) {
      query.month = month.value;
    } else {
      delete query["month"];
    }

    if (year) {
      query.year = year.value;
    } else {
      delete query["year"];
    }
    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  if (loading) {
    return <FullScreenLoading />;
  }
  const {
    sps_1_male,
    sps_1_female,
    sps_1_total,

    sps_2_male,
    sps_2_female,
    sps_2_total,

    sps_3_male,
    sps_3_female,
    sps_3_total,

    package_1,
    package_2,
    package_3,
    package_4,
    package_5,
    package_6,
    package_7,
    package_8,
    package_9,
  } = report;

  const data = [
    {
      title: "No.of pre-enrolled DR patient supported",
      male: sps_1_male,
      female: sps_1_female,
      total: sps_1_total,
      remark:
        "Count of patients who has Lab code but still do not have DRTB code",
    },
    {
      title: "No.of enrolled DR patient supported",
      male: sps_2_male,
      female: sps_2_female,
      total: sps_2_total,
      remark: "Count of patients who has DRTB code",
    },
    {
      title: "No.of total DR patient supported",
      male: sps_3_male,
      female: sps_3_female,
      total: sps_3_total,
      remark: "Total Count of patients who has Lab code and DRTB code",
    },
    {
      title: "No. packages supported for specialist consultation (package 1)",
      male: "no data",
      female: "no data",
      total: package_1,
      remark: "Count of patients who receive package 1 and its reimbursement",
    },
    {
      title: "No. packages supported for Hearing aid support (package 2)",
      male: "no data",
      female: "no data",
      total: package_2,
      remark: "Count of patients who receive package 2 and its reimbursement ",
    },
    {
      title: "No. packages supported for Palliative care support (package 3)",
      male: "no data",
      female: "no data",
      total: package_3,
      remark: "Count of patients who receive package 3 and its reimbursement",
    },
    {
      title: "No. packages supported for Hospitalization support (package 4)",
      male: "no data",
      female: "no data",
      total: package_4,
      remark: "Count of patients who receive package 4 and its reimbursement",
    },
    {
      title:
        "No. packages supported for DR-TB hero who completed the treatment (package 5)",
      male: "no data",
      female: "no data",
      total: package_5,
      remark: "Count of patients who receive package 5 and its reimbursement",
    },
    {
      title:
        "No. packages supported for Housing support for initial treatment period (package 6)",
      male: "no data",
      female: "no data",
      total: package_6,
      remark: "Count of patients who receive package 6 and its reimbursement",
    },
    {
      title:
        "No. packages supported for Pre-enrollment support for investigatioin and travel cost from hard to reach area to treatment centre (package 7)",
      male: "no data",
      female: "no data",
      total: package_7,
      remark: "Count of patients who receive package 7 and its reimbursement",
    },
    {
      title: "No. packages supported for Nutritional support (package 8)",
      male: "no data",
      female: "no data",
      total: package_8,
      remark: "Count of patients who receive package 8 and its reimbursement",
    },
    {
      title: "No. packages supported for follow-up/referral (package 9)",
      male: "no data",
      female: "no data",
      total: package_9,
      remark: "Count of patients who receive package 9 and its reimbursement",
    },
  ];
  const handleRefresh = () => {
    router.replace(router.location.pathname);
  };

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">SPS Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={"Monthly-Report"}
                sheet="Monthly-Report"
                buttonText="Export"
              />
              <Button size="sm" color="primary" onClick={handleRefresh}>
                <i class="fas fa-redo"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col sm={3}>
              <Select
                className="mt-3"
                options={townships}
                value={selectedTownship}
                onChange={(value) => setSelectedTownship(value)}
                placeholder="Filter by township..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>
          </Row>
        </CardHeader>

        <table id="table-to-xls" className={styles.styled_table}>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Indicators</th>
              <th scope="col"></th>
              <th scope="col">Male</th>
              <th scope="col">Female</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{row?.title}</td>
                  <td>
                    <i
                      className={classnames(
                        "fa fa-question-circle",
                        styles.tooltip
                      )}
                    >
                      <span className={classnames(styles.tooltiptext)}>
                        {row?.remark}
                      </span>
                    </i>
                  </td>
                  <td className={row?.male === "no data" ? "bg-dark" : ""}>
                    {row?.male !== "no data" && row?.male}
                  </td>
                  <td className={row?.male === "no data" ? "bg-dark" : ""}>
                    {row?.female !== "no data" && row?.female}
                  </td>
                  <td>{row?.total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </Container>
  );
};

export default Report;
