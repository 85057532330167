import React, { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import queryString from "query-string";
import { months, years } from "variables/options";
import { call } from "services/api";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import Select from "react-select";
import { CPIReportTitle } from "utils/CPIReportTitle";
import { useDispatch, useSelector } from "react-redux";
import { getTownships } from "store/actions/township";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "./CPIReport.module.css";
import { getProjects } from "store/actions/project";

const CPIReportList = () => {
  const router = useHistory();

  const [loading, setLoading] = useState(false);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [packageTitles, setPackageTitles] = useState([]);
  const [packageExports, setPackageExports] = useState([]);

  const township = useSelector((state) => state.township);
  const project = useSelector((state) => state.project);

  const townships = township.townships?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const projects = project.projects?.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const dispatch = useDispatch();

  const handleFetchTownship = useCallback(() => {
    const query = queryString.parse(router.location.search);

    if (selectedProject) {
      query.project_id = selectedProject.value;
    }
    dispatch(getTownships(query));
  }, [selectedProject, dispatch, router.location.search]);

  useEffect(() => {
    dispatch(getProjects());
    handleFetchTownship();
  }, [dispatch, handleFetchTownship]);

  const handleReport = async () => {
    setLoading(true);
    if (startYear && endYear) {
      if (startYear.value <= endYear.value) {
        try {
          const query = queryString.parse(router.location.search);

          query.start_year = startYear.value;
          query.end_year = endYear.value;

          if (selectedProject) {
            query.project_id = selectedProject.value;
          }

          if (selectedTownship) {
            query.township_id = selectedTownship.value;
          }

          let titles = [];
          for (let i = startYear.value; i <= endYear.value; i++) {
            titles = titles.concat(
              months?.map((month) => i + "-" + month.label)
            );
          }

          setPackageTitles(titles);

          const response = await call(
            "get",
            `CPI-report?${new URLSearchParams(query).toString()}`
          );

          if (response.status === "success") {
            let result = response.data;

            setPackageExports(result);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select start year and end year!");
    }
    setLoading(false);
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3">
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">CPI Report</h3>
              </Col>
              <Col xs="6">
                <ReactHTMLTableToExcel
                  className="btn btn-success btn-sm"
                  table="table-to-xls"
                  filename={"CPI-Report"}
                  sheet="CPI-Report"
                  buttonText="Export"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                <Select
                  className="mt-2"
                  options={years}
                  value={startYear}
                  onChange={(value) => setStartYear(value)}
                  placeholder="Start year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={2}>
                <Select
                  className="mt-2"
                  options={years}
                  value={endYear}
                  onChange={(value) => setEndYear(value)}
                  placeholder="End year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={3}>
                <Select
                  className="mt-2"
                  options={projects}
                  value={selectedProject}
                  onChange={(value) => {
                    setSelectedProject(value);
                    setSelectedTownship(null);
                  }}
                  placeholder="Filter by project..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={3}>
                <Select
                  className="mt-2"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col md={2}>
                <Button
                  size="sm"
                  color="info"
                  onClick={handleReport}
                  disabled={loading}
                  className="mt-2"
                >
                  {loading ? "Loading" : "Report"}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ width: "100%", overflowX: "scroll" }}>
            <table id="table-to-xls" className={styles.styled_table}>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  {packageTitles?.map((title, index) => (
                    <th key={index}>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {packageExports?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.package_no}</td>
                    <td>{CPIReportTitle[row.package_no]}</td>
                    {packageTitles?.map((title, index) => (
                      <td key={index}>{row[`${title}-${row.package_no}`]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default CPIReportList;
